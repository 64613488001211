import { getFormattedIntFraction, getFormattedDecimalFraction } from '@vpp-frontend-components/price';

const PRICE_PER_QUANTITY = 'price_per_quantity';
const MAX_QUANTITY = 100000;
const ABOVE_MAX_QUANTITY_LABEL = '+100.000';

export default {
  has(customOptions) {
    return this.filterItemsPricePerQuantity(customOptions).length > 0;
  },
  filterItemsPricePerQuantity(customOptions) {
    return customOptions?.filter(item => item.type === PRICE_PER_QUANTITY) ?? [];
  },
  getItemsFormatted(customOptions) {
    const pricePerQuantityItems = this.filterItemsPricePerQuantity(customOptions);
    return pricePerQuantityItems.map(({ quantity, display_value }) => {
      const cents = getFormattedDecimalFraction(display_value?.amount?.value, '.');

      return {
        quantity: {
          value: Number(quantity),
          text: display_value.quantity.text,
        },
        amount: {
          fraction: getFormattedIntFraction(display_value?.amount?.value, '.'),
          cents: cents < 1 ? undefined : cents,
          decimal_style: display_value?.amount?.decimal_style?.toLowerCase(),
          currency_id: display_value?.amount?.currency_id,
          suffix: display_value?.amount.suffix,
        },
      };
    });
  },
  verifyQuantity(customOptions, quantity) {
    return this.filterItemsPricePerQuantity(customOptions).some(item => item.quantity === quantity)
      ? ''
      : String(quantity);
  },
  getQuantityText(value, { singular, plural } = {}) {
    if (value >= MAX_QUANTITY) {
      return plural?.replace('{quantity}', ABOVE_MAX_QUANTITY_LABEL) || '';
    }

    const quantityText = value > 1 ? plural : singular;

    return quantityText?.replace('{quantity}', value) || '';
  },
  getAmountByQuantity(customOptions, quantity) {
    const result = this.getItemsFormatted(customOptions)
      .reverse()
      .find(item => item.quantity.value <= quantity);

    return result ? result.amount : {};
  },
};
