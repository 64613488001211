import React from 'react';
import { shape, string, node, bool } from 'prop-types';
import classnames from 'classnames';
import { Message } from '@andes/message';
import StyledLabel from '../../styled-label';
import componentEnhance from '../../../lib/component-enhance';
import { validateSnackbarColor } from '../../../utils/validators';

const namespace = 'ui-pdp-buybox__quantity';

const Quantity = ({ children, className, picker, hasCustomOptions }) => (
  <div className={classnames(namespace, className, { [`${namespace}__custom-options`]: hasCustomOptions })}>
    {children}
    {picker && (
      <>
        <input type="hidden" name="quantity" value={picker.selected} />
        {picker.messages && (
          <div className={`${namespace}__messages`}>
            {picker.messages.map(message => (
              <StyledLabel
                className={classnames(`${namespace}__messages__message`)}
                key={message.text}
                as="p"
                {...message}
                text={componentEnhance.jsx(message.text, message.values)}
              />
            ))}
          </div>
        )}
        {picker.disclaimer && picker.disclaimer.state === 'VISIBLE' && (
          <div className={`${namespace}__disclaimer`}>
            <Message
              hierarchy={picker.disclaimer.hierarchy}
              color={validateSnackbarColor(picker.disclaimer.message_type)}
              closeable={picker.disclaimer.closeable}
            >
              <p>{picker.disclaimer.body.text}</p>
            </Message>
          </div>
        )}
      </>
    )}
  </div>
);

Quantity.propTypes = {
  className: string,
  picker: shape({
    title: string,
    selected_label: shape({
      text: string,
      color: string,
    }),
    border_color: string,
    description: string,
    disclaimer: shape({
      state: string,
      hierarchy: string,
      message_type: string,
      closeable: bool,
      body: shape({
        text: string,
      }),
    }),
  }),
  children: node.isRequired,
  hasCustomOptions: bool,
};

Quantity.defaultProps = {
  className: null,
  picker: null,
  hasCustomOptions: false,
};

export default Quantity;
