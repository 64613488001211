import APIService from '../../../services/api';
import { saveForm } from '../../../form/local-storage/unregistered-questions-local-storage';
import { onLoadRecaptchav3Token } from '../../../components/recaptcha';
import { groupedModelUnits } from '../../../components/quotations/helpers';
import { trackEvent } from '../../../lib/tracking';

import {
  CREATE_QUESTIONS_UNREGISTERED_START,
  CREATE_QUESTIONS_UNREGISTERED_COMPLETE,
  CREATE_QUESTIONS_UNREGISTERED_ERROR,
  FETCH_CONTACT_INFO_ERROR,
  FETCH_COMPONENT,
  FETCH_START,
  FETCH_BUDGET_COMPONENTS,
  FETCH_ERROR,
  SHOW_RECAPTCHA,
  FETCH_SELLER_INFO_PHONES,
  QUESTIONS_UNREGISTERED_ERROR_FORM,
  FETCH_MODAL_QUESTIONS_START,
  FETCH_MODAL_QUESTIONS_COMPLETE,
  FETCH_MODAL_QUESTIONS_ERROR,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_START,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_COMPLETE,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_ERROR,
  RENDER_FRAUD_MODAL,
  REMOVE_RECAPTCHA,
  QUESTIONS_UNREGISTERED_EDIT_DATASTORED,
  QUESTIONS_UNREGISTERED_ON_CHANGE_VALUE,
  MODAL_REQUEST_START,
  MODAL_REQUEST_CLOSE,
  MODAL_REQUEST_RESIZING,
  SHOW_FRAUD_SNACKBAR,
  SCROLL_TO_COMPONENT,
  UPDATE_TOKEN_RESPONSE,
  CREATE_BUDGET_START,
  CREATE_BUDGET_ERROR,
  FETCH_QUOTATION_START,
  FETCH_QUOTATION_COMPLETE,
  FETCH_QUOTATION_PRICE,
  FETCH_QUOTATION_ERROR,
  UPDATE_QUOTATION_FORM,
  CREATE_QUOTATION_START,
  CREATE_QUOTATION_SHOW_PRICE,
  CREATE_QUOTATION_COMPLETE,
  CREATE_QUOTATION_ERROR,
  UPDATE_QUOTATION_CURRENT_STATE,
  FETCH_GET_UNITS_START,
  UPDATE_GET_UNITS_CURRENT_STATE,
  FETCH_GET_UNITS_COMPLETE,
  FETCH_GET_UNITS_ERROR,
  FETCH_VEHICLE_HISTORY_MODAL,
  QUOTATION_ERROR_EXCEEDS_LIMIT,
} from './types';

import { CREATE_QUESTION_START, CREATE_QUESTION_COMPLETE, CREATE_QUESTION_ERROR, SHOW_SNACKBAR } from '../types';

import { IS_POSTING, QUOTATION_ERRORS } from '../../../components/unregistered-modal/utils/constants';

const handleRedirect = (payload, propagateHash = false) => {
  const hash = propagateHash && (window.location?.hash || '');
  window.location = `${payload.redirect.permalink}${hash}`;
};

const dispatchCreateQuestionUnregistered = (
  { component, itemId, form, outPutValues, repcatchaV2Token, app, track, origin, action },
  dispatch,
) => recaptchaTokenv3 => {
  dispatch({ type: FETCH_COMPONENT, isFetchingData: true, component });
  return APIService.createQuestionsUnregistered({
    itemId,
    form: outPutValues,
    recaptchaTokenv3,
    repcatchaV2Token,
    params: { app, origin },
    action,
  })
    .then(payload => {
      dispatch({ type: FETCH_COMPONENT, isFetchingData: false, component });
      if (payload.success) {
        if (!payload.is_repeated) {
          trackEvent(track);
        }
        saveForm({ input: payload.unregistered_questions.form });
        dispatch({ type: MODAL_REQUEST_CLOSE });
      } else if (payload.has_validation_errors) {
        payload.display_message = null;
      }
      // eslint-disable-next-line promise/always-return
      if (payload.redirect) {
        handleRedirect(payload);
      } else if (payload.recaptcha) {
        dispatch({ type: SHOW_RECAPTCHA, payload: { ...payload, component } });
        dispatch({ type: MODAL_REQUEST_RESIZING });
      } else {
        dispatch({ type: CREATE_QUESTIONS_UNREGISTERED_COMPLETE, payload, component });
      }
    })
    .catch(e => {
      dispatch({ type: CREATE_QUESTIONS_UNREGISTERED_ERROR, error: e, form, component });
    });
};

const onCreateQuestionsUnregistered = ({
  component,
  action,
  itemId,
  form,
  outPutValues,
  hasErrors,
  repcatchaV2Token,
  track,
  origin,
  recaptchaSiteKey,
}) => (dispatch, getState) => {
  const { app } = getState();
  dispatch({ type: CREATE_QUESTIONS_UNREGISTERED_START, form, itemId, hasErrors, component });
  if (repcatchaV2Token) {
    dispatchCreateQuestionUnregistered(
      { component, itemId, form, outPutValues, repcatchaV2Token, app, track, origin, action },
      dispatch,
    )();
  } else {
    onLoadRecaptchav3Token(
      recaptchaSiteKey,
      action,
      dispatchCreateQuestionUnregistered(
        { component, itemId, form, outPutValues, app, track, origin, action },
        dispatch,
      ),
      e => dispatch({ type: CREATE_QUESTIONS_UNREGISTERED_ERROR, error: e, form, component }),
    );
  }
};

const dispatchContactInfoErrors = (dispatch, component) => {
  dispatch({ type: FETCH_COMPONENT, isFetchingData: false, component });
  dispatch({ type: FETCH_CONTACT_INFO_ERROR, component });
};

const getContactInfoTarget = payload => {
  let hrefTarget = null;
  if (payload.whatsapp) {
    hrefTarget = payload.whatsapp.target;
  } else if (payload.phones) {
    const value = payload.phones.find(phoneValue => phoneValue);
    hrefTarget = value.target;
  }
  return hrefTarget;
};

const redirectReauthUrl = requestError => {
  if (requestError?.response?.status === 401 && !!requestError?.response?.data?.require_reauth) {
    const reauthUrl = requestError.response.data.url;
    if (reauthUrl) {
      window.location.href = reauthUrl;
      return true;
    }
  }
  return false;
};

const dispatchContactInfo = (
  { component, itemId, contactType, recaptchav2token, ajaxBackendParams, action, recaptchaActionType, params },
  dispatch,
) => recaptchaTokenv3 =>
  APIService.getSellerContactInfo(
    itemId,
    contactType,
    recaptchaTokenv3,
    recaptchav2token,
    ajaxBackendParams,
    action,
    params,
  )
    .then(payload => {
      dispatch({ type: FETCH_COMPONENT, isFetchingData: false, component });
      // eslint-disable-next-line promise/always-return
      if (payload.recaptcha) {
        trackEvent(payload.recaptcha.track);
        dispatch({ type: SHOW_RECAPTCHA, payload: { ...payload, component, recaptchaActionType } });
      } else if (payload.display_message) {
        dispatch({ type: SHOW_FRAUD_SNACKBAR, payload: { ...payload, component } });
      } else if (payload.fraud_modal) {
        dispatch({ type: RENDER_FRAUD_MODAL, modal: payload.fraud_modal });
      } else if (contactType === 'show-phones') {
        trackEvent(payload.track);
        dispatch({ type: FETCH_SELLER_INFO_PHONES, payload });
      } else {
        trackEvent(payload.track);
        const target = getContactInfoTarget(payload);
        if (target) {
          dispatch({ type: UPDATE_TOKEN_RESPONSE, payload: { component, token: null } });
          window.location.href = target;
        } else {
          dispatch({ type: FETCH_CONTACT_INFO_ERROR, component });
        }
      }
    })
    .catch(e => {
      if (redirectReauthUrl(e)) {
        return;
      }

      dispatchContactInfoErrors(dispatch, component);
    });

const fetchContactInfo = ({
  component,
  itemId,
  contactType,
  action,
  recaptchav2token,
  ajaxBackendParams,
  recaptchaActionType,
  recaptchaSiteKey,
  params = {},
}) => (dispatch, getState) => {
  const { vertical } = getState();
  params.vertical = vertical;
  dispatch({ type: FETCH_COMPONENT, isFetchingData: true, component });
  if (recaptchav2token) {
    dispatch({ type: REMOVE_RECAPTCHA, payload: { component } });
    dispatchContactInfo(
      { component, itemId, contactType, recaptchav2token, ajaxBackendParams, action, recaptchaActionType, params },
      dispatch,
    )();
  } else {
    onLoadRecaptchav3Token(
      recaptchaSiteKey,
      action,
      dispatchContactInfo(
        { component, itemId, contactType, ajaxBackendParams, action, recaptchaActionType, params },
        dispatch,
      ),
      () => dispatchContactInfoErrors(dispatch, component),
    );
  }
};

const onSetQuestionsUnregisteredFormError = ({ form, component }) => dispatch => {
  dispatch({
    type: QUESTIONS_UNREGISTERED_ERROR_FORM,
    payload: { form, component },
  });
};

const fetchModalQuestions = (itemId, variationId, actionType) => (dispatch, getState) => {
  dispatch({ type: MODAL_REQUEST_START });
  dispatch({ type: FETCH_MODAL_QUESTIONS_START });
  const { app } = getState();

  APIService.getModalQuestions(itemId, { app, variation_id: variationId, action_type: actionType })
    .then(payload => {
      dispatch({
        type: FETCH_MODAL_QUESTIONS_COMPLETE,
        payload,
      });
    })
    .catch(e => dispatch({ type: FETCH_MODAL_QUESTIONS_ERROR, error: e }));
};

const fetchModalUnregisteredQuestions = (itemId, variationId) => (dispatch, getState) => {
  dispatch({ type: MODAL_REQUEST_START });
  dispatch({ type: FETCH_MODAL_UNREGISTERED_QUESTIONS_START });
  const { app } = getState();
  APIService.getModalUnregisteredQuestions(itemId, { app, variation_id: variationId })
    .then(payload => {
      dispatch({
        type: FETCH_MODAL_UNREGISTERED_QUESTIONS_COMPLETE,
        payload,
      });
    })
    .catch(e => dispatch({ type: FETCH_MODAL_UNREGISTERED_QUESTIONS_ERROR, error: e }));
};

const removeRecaptcha = component => dispatch => {
  dispatch({ type: REMOVE_RECAPTCHA, payload: { component } });
};

const onCreateOnlyQuestion = ({ itemId, text, track, component, checkbox }) => (dispatch, getState) => {
  const { app, vertical } = getState();
  dispatch({ type: CREATE_QUESTION_START, itemId, component });
  APIService.createQuestion(itemId, text, { app, make_only_question: true, checkbox, vertical })
    .then(payload => {
      if (payload) {
        if (payload.success === true) {
          if (!payload.is_repeated) {
            trackEvent(track);
          }
          dispatch({ type: MODAL_REQUEST_CLOSE });
        }

        if (payload.redirect) {
          handleRedirect(payload, true);
        } else {
          dispatch({ type: CREATE_QUESTION_COMPLETE, payload, component });
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: payload.display_message.text,
              type: 'success',
              called_from: 'vis',
            },
          });
        }
      }
    })
    .catch(e => {
      if (redirectReauthUrl(e)) {
        return;
      }

      dispatch({ type: MODAL_REQUEST_CLOSE });
      if (e.response && e.response.data && e.response.data.displayMessage) {
        dispatch({ type: CREATE_QUESTION_ERROR, component, error: e.response.data.displayMessage });
        dispatch({
          type: SHOW_SNACKBAR,
          params: {
            message: e.response.data.displayMessage,
            type: 'error',
            called_from: 'vis',
          },
        });
        return;
      }
      dispatch({ type: CREATE_QUESTION_ERROR, component, error: e });
    });
};

const onChangeUnregisteredQuestionValue = ({ input, value }) => dispatch => {
  dispatch({ type: QUESTIONS_UNREGISTERED_ON_CHANGE_VALUE, payload: { input, value } });
};

const onScrollComponent = ({ component, focus }) => dispatch => {
  dispatch({ type: SCROLL_TO_COMPONENT, payload: { component, focus } });
};

const updateTokenResponse = ({ component, token }) => dispatch => {
  dispatch({ type: UPDATE_TOKEN_RESPONSE, payload: { component, token } });
};

const onEnableStorageDataInQuestionsUnregisteredForm = ({ canEdit, isEditing, component }) => dispatch => {
  dispatch({ type: QUESTIONS_UNREGISTERED_EDIT_DATASTORED, canEdit, isEditing, component });
  dispatch({ type: MODAL_REQUEST_RESIZING });
};

const redirectToLogin = ({ itemId, loginType, featureName }) => () => {
  APIService.redirectToLogin(itemId, loginType, featureName);
};

const fetchBudgetComponents = ({ id, app, message }, dispatch) => {
  APIService.getProduct(id, {
    components_ids: 'main_actions,new_budget_message,budget_sent_message,budget_demand',
    app,
    device: 'desktop',
  })
    .then(payload => {
      payload.service_flash_message = message;
      dispatch({ type: FETCH_BUDGET_COMPONENTS, payload });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const updateBudgetComponents = message => (dispatch, getState) => {
  const { id, app } = getState();
  dispatch({ type: FETCH_START, id });
  fetchBudgetComponents({ id, app, message }, dispatch);
};

const onCreateBudgetDemmand = ({ itemId, text, track }) => (dispatch, getState) => {
  const { id, app } = getState();
  dispatch({ type: CREATE_BUDGET_START, itemId });
  APIService.createBudgetDemand(itemId, text, { app })
    .then(payload => {
      if (payload.success) {
        trackEvent(track);
      }

      fetchBudgetComponents(
        {
          id,
          app,
          message: { message: payload.display_message.text, show: true, type: payload.success ? 'success' : 'error' },
        },
        dispatch,
      );
    })
    .catch(e => {
      dispatch({ type: CREATE_BUDGET_ERROR, error: e });
    });
};

const getSelectionState = (modelId, modelUnits) => ({
  selectedModelId: modelId,
  selectedUnit: modelUnits[modelId][0],
});

const getModelUnits = (modelId, deviceType, currentState) => (dispatch, getState) => {
  dispatch({ type: MODAL_REQUEST_START });
  dispatch({ type: FETCH_QUOTATION_START });
  const { app, id, quotation, picture_config } = getState();
  const currentUnits = quotation ? quotation.model_units : {};

  if (quotation && quotation.model_units && quotation.model_units[modelId]) {
    dispatch({
      type: UPDATE_QUOTATION_CURRENT_STATE,
      payload: {
        ...currentState,
        ...getSelectionState(modelId, quotation.model_units),
        isFetching: false,
      },
    });
    return;
  }

  APIService.getModelUnits(id, { app, model_id: modelId, deviceType })
    .then(payload => {
      const quotationComponent = payload.components.find(component => component.id === 'quotation');
      const model_units = { ...currentUnits, ...groupedModelUnits(quotationComponent.model_units, picture_config) };
      const exceedQuotationLimit = quotationComponent?.snackbar?.quotation_limit;

      dispatch({
        type: FETCH_QUOTATION_COMPLETE,
        payload: { ...quotationComponent, model_units },
      });
      const defaultModelId = modelId || quotationComponent.models[0].id;
      dispatch({
        type: UPDATE_QUOTATION_CURRENT_STATE,
        payload: {
          ...currentState,
          isFetching: false,
          ...getSelectionState(defaultModelId, model_units),
        },
      });
      if (exceedQuotationLimit) {
        dispatch({ type: QUOTATION_ERROR_EXCEEDS_LIMIT, payload: exceedQuotationLimit });
      }
    })
    .catch(e => dispatch({ type: FETCH_QUOTATION_ERROR, error: e }));
};
const getPriceModelUnits = (modelId, deviceType, currentState) => (dispatch, getState) => {
  dispatch({ type: MODAL_REQUEST_START });
  dispatch({ type: FETCH_QUOTATION_START });
  const { app, id, quotation, picture_config } = getState();
  const currentUnits = quotation ? quotation.model_units : {};

  APIService.getModelUnits(id, { app, model_id: modelId, deviceType })
    .then(payload => {
      const quotationComponent = payload.components.find(component => component.id === 'quotation');
      const model_units = { ...currentUnits, ...groupedModelUnits(quotationComponent.model_units, picture_config) };
      dispatch({
        type: FETCH_QUOTATION_PRICE,
        payload: { ...quotationComponent, model_units },
      });
      const defaultModelId = modelId || quotationComponent.models[0].id;
      dispatch({
        type: UPDATE_QUOTATION_CURRENT_STATE,
        payload: {
          ...currentState,
          isFetching: false,
          ...getSelectionState(defaultModelId, model_units),
        },
      });
      dispatch({ type: CREATE_QUOTATION_SHOW_PRICE, payload: 'show_price' });
      trackEvent(quotationComponent.quotation_track);
    })
    .catch(e => dispatch({ type: FETCH_QUOTATION_ERROR, error: e }));
};

const getUnitsLTR = (modelId, deviceType, currentState) => (dispatch, getState) => {
  dispatch({ type: MODAL_REQUEST_START });
  dispatch({ type: FETCH_GET_UNITS_START });
  const { app, id, multifamily_modal, picture_config } = getState();
  const currentUnits = multifamily_modal ? multifamily_modal.model_units : {};

  if (multifamily_modal?.model_units?.[modelId]) {
    dispatch({
      type: UPDATE_GET_UNITS_CURRENT_STATE,
      payload: {
        ...currentState,
        ...getSelectionState(modelId, multifamily_modal.model_units),
        isFetching: false,
      },
    });
    return;
  }

  APIService.getUnitsLTR(id, { app, model_id: modelId, deviceType })
    .then(payload => {
      const ltrComponent = payload.components.find(component => component.id === 'check_units');
      const model_units = { ...currentUnits, ...groupedModelUnits(ltrComponent.model_units, picture_config) };
      dispatch({
        type: FETCH_GET_UNITS_COMPLETE,
        payload: { ...ltrComponent, model_units },
      });
      const defaultModelId = modelId || ltrComponent.models[0].id;
      dispatch({
        type: UPDATE_GET_UNITS_CURRENT_STATE,
        payload: {
          ...currentState,
          isFetching: false,
          ...getSelectionState(defaultModelId, model_units),
        },
      });
    })
    .catch(e => dispatch({ type: FETCH_GET_UNITS_ERROR, error: e }));
};

const fetchUnregisteredQuotations = (modelId, deviceType, currentState) => (dispatch, getState) => {
  dispatch({ type: MODAL_REQUEST_START });
  dispatch({ type: FETCH_QUOTATION_START });
  const { app, id, quotation, picture_config } = getState();
  const currentUnits = quotation ? quotation.model_units : {};

  if (quotation && quotation.model_units && quotation.model_units[modelId]) {
    dispatch({
      type: UPDATE_QUOTATION_CURRENT_STATE,
      payload: {
        ...currentState,
        ...getSelectionState(modelId, quotation.model_units),
        isFetching: false,
      },
    });
    return;
  }

  APIService.getUnregisteredQuotations(id, { app, model_id: modelId, deviceType })
    .then(payload => {
      const quotationComponent = payload.components.find(component => component.id === 'quotation');
      const model_units = { ...currentUnits, ...groupedModelUnits(quotationComponent.model_units, picture_config) };
      dispatch({
        type: FETCH_QUOTATION_COMPLETE,
        payload: { ...quotationComponent, model_units },
      });
      const defaultModelId = modelId || quotationComponent.models[0].id;
      dispatch({
        type: UPDATE_QUOTATION_CURRENT_STATE,
        payload: {
          ...currentState,
          isFetching: false,
          ...getSelectionState(defaultModelId, model_units),
        },
      });
    })
    .catch(e => dispatch({ type: FETCH_QUOTATION_ERROR, error: e }));
};

const updateQuotationForm = form => dispatch => {
  dispatch({ type: UPDATE_QUOTATION_FORM, payload: form });
};

const onCreateQuotation = ({ unitId, recaptchaTokenV2, track, recaptchaSiteKey }) => (dispatch, getState) => {
  const { id, app } = getState();
  dispatch({ type: CREATE_QUOTATION_START });
  const existingArrayString = localStorage.getItem('quoted-units');
  const existingArray = existingArrayString ? JSON.parse(existingArrayString) : [];

  const getRecaptchaTokenv3 = new Promise(resolve => {
    if (!recaptchaTokenV2) {
      onLoadRecaptchav3Token(recaptchaSiteKey, 'quotation', recaptchaTokenv3 => resolve(recaptchaTokenv3));
    } else {
      resolve(null);
    }
  });

  getRecaptchaTokenv3
    .then(recaptchaTokenV3 => {
      APIService.createQuotation(
        { itemId: id, unitId, recaptchaTokenV2, action: 'quotation', recaptchaTokenV3 },
        { app },
      )
        .then(payload => {
          if (payload.success === true) {
            trackEvent(track);
            if (!existingArray.includes(unitId)) {
              existingArray.push(unitId);
              localStorage.setItem('quoted-units', JSON.stringify(existingArray));
            }
          }
          dispatch({ type: CREATE_QUOTATION_COMPLETE });
          if (payload.redirect) {
            handleRedirect(payload, true);
          }
          if (payload.success === true && !payload.redirect) {
            dispatch({ type: CREATE_QUOTATION_SHOW_PRICE, payload: 'congrats' });
          }
          if (payload.recaptcha) {
            dispatch({ type: SHOW_RECAPTCHA, payload: { ...payload, component: 'quotation' } });
          } else if (!payload.success && !payload.has_validation_errors) {
            dispatch({ type: CREATE_QUOTATION_ERROR, error: payload });
          }
        })
        .catch(e => {
          dispatch({ type: CREATE_QUOTATION_ERROR, error: e });
        });
    })
    .catch(e => {
      dispatch({ type: CREATE_QUOTATION_ERROR, error: e });
    });
};

const onShowPrice = ({ unitId, modelId, deviceType, currentState, recaptchaTokenV2, recaptchaSiteKey }) => (
  dispatch,
  getState,
) => {
  const { id, app } = getState();

  const getRecaptchaTokenv3 = new Promise(resolve => {
    if (!recaptchaTokenV2) {
      onLoadRecaptchav3Token(recaptchaSiteKey, 'quotation', recaptchaTokenv3 => resolve(recaptchaTokenv3));
    } else {
      resolve(null);
    }
  });

  getRecaptchaTokenv3.then(recaptchaTokenV3 => {
    APIService.createQuotationPrice(
      {
        itemId: id,
        unitId,
        recaptchaTokenV2,
        action: 'quotation',
        recaptchaTokenV3,
        quotationType: 'show-prices',
      },
      { app },
    )
      .then(payload => {
        if (payload.success === true) {
          dispatch(getPriceModelUnits(modelId, deviceType, currentState));
        }
        if (payload.recaptcha) {
          dispatch({ type: SHOW_RECAPTCHA, payload: { ...payload, component: 'quotation' } });
        } else if (!payload.success && !payload.has_validation_errors) {
          dispatch({ type: CREATE_QUOTATION_ERROR, error: payload });
        }
      })
      .catch(e => {
        dispatch({ type: CREATE_QUOTATION_ERROR, error: e });
      });
  });
};

const onCreateUnregisteredQuotation = ({
  unitId,
  recaptchaTokenV2,
  form,
  track,
  recaptchaSiteKey,
  currentState,
  handleClose,
}) => (dispatch, getState) => {
  const { id, app } = getState();
  dispatch({ type: CREATE_QUOTATION_START });

  const getRecaptchaTokenv3 = new Promise(resolve => {
    if (!recaptchaTokenV2) {
      onLoadRecaptchav3Token(recaptchaSiteKey, 'quotation', recaptchaTokenv3 => resolve(recaptchaTokenv3));
    } else {
      resolve(null);
    }
  });

  getRecaptchaTokenv3
    .then(recaptchaTokenV3 => {
      APIService.createUnregisteredQuotation(
        { itemId: id, unitId, recaptchaTokenV2, recaptchaTokenV3, action: 'quotation', form },
        { app },
      )
        .then(payload => {
          localStorage.setItem(IS_POSTING, true);
          if (payload.success === true) {
            trackEvent(track);
            const existingArrayString = localStorage.getItem('quoted-units');
            const existingArray = existingArrayString ? JSON.parse(existingArrayString) : [];
            if (!existingArray.includes(unitId)) {
              existingArray.push(unitId);
              localStorage.setItem('quoted-units', JSON.stringify(existingArray));
            }
            localStorage.removeItem(IS_POSTING);

            if (!payload.redirect) {
              dispatch({ type: CREATE_QUOTATION_SHOW_PRICE, payload: 'congrats' });
              if (currentState) {
                dispatch({ type: UPDATE_QUOTATION_CURRENT_STATE, payload: { ...currentState, step: 0 } });
              }
              if (handleClose) {
                handleClose();
              }
            }
          }
          dispatch({ type: CREATE_QUOTATION_COMPLETE });
          if (payload.redirect) {
            handleRedirect(payload, true);
          }
          if (payload.form) {
            dispatch({ type: UPDATE_QUOTATION_FORM, payload: payload.form.form });
          }
          if (payload.recaptcha) {
            dispatch({ type: SHOW_RECAPTCHA, payload: { ...payload, component: 'quotation' } });
          } else if (!payload.success && !payload.has_validation_errors) {
            if (payload.error_code === QUOTATION_ERRORS.PRECONDITION_FAILED) {
              dispatch({ type: QUOTATION_ERROR_EXCEEDS_LIMIT, payload });
            } else {
              localStorage.removeItem(IS_POSTING);
              dispatch({ type: CREATE_QUOTATION_ERROR, error: payload });
            }
          }
        })
        .catch(e => {
          localStorage.removeItem(IS_POSTING);
          dispatch({ type: CREATE_QUOTATION_ERROR, error: e });
        });
    })
    .catch(e => {
      localStorage.removeItem(IS_POSTING);
      dispatch({ type: CREATE_QUOTATION_ERROR, error: e });
    });
};

const updateQuotationCurrentState = currentState => dispatch => {
  dispatch({ type: UPDATE_QUOTATION_CURRENT_STATE, payload: currentState });
};

const updateLTRUnitsCurrentState = currentState => dispatch => {
  dispatch({ type: UPDATE_GET_UNITS_CURRENT_STATE, payload: currentState });
};

const fetchVehicleHistoryModal = itemId => dispatch => {
  APIService.getVehicleHistoryModal(itemId)
    .then(payload => {
      dispatch({ type: FETCH_VEHICLE_HISTORY_MODAL, payload });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

export {
  onCreateQuestionsUnregistered,
  fetchContactInfo,
  updateBudgetComponents,
  onSetQuestionsUnregisteredFormError,
  fetchModalQuestions,
  fetchModalUnregisteredQuestions,
  onEnableStorageDataInQuestionsUnregisteredForm,
  removeRecaptcha,
  onCreateOnlyQuestion,
  onChangeUnregisteredQuestionValue,
  onScrollComponent,
  redirectToLogin,
  updateTokenResponse,
  onCreateBudgetDemmand,
  getModelUnits,
  getUnitsLTR,
  fetchUnregisteredQuotations,
  updateQuotationForm,
  onCreateQuotation,
  onCreateUnregisteredQuotation,
  updateQuotationCurrentState,
  updateLTRUnitsCurrentState,
  onShowPrice,
  getPriceModelUnits,
  fetchVehicleHistoryModal,
};
