/* eslint-disable complexity */
import React, { useState, useRef, useCallback, useEffect, startTransition, useContext } from 'react';
import { bool, number, func, oneOfType, shape, string, arrayOf } from 'prop-types';
import { FocusScope } from '@react-aria/focus';
import formatBulkQuantity from '../../../utils/formatBulkQuantity';
import { Tooltip } from '@andes/tooltip';
import { List, ListItem } from '@andes/list';
import { MoneyAmount } from '@andes/money-amount';
import classnames from 'classnames';
import QuantityInput from './quantity-input';
import QuantityTrigger from './quantity-trigger';
import { trackQuantityEvent, trackEvent } from '../../../lib/tracking';
import HistoryService from '../../../services/history';
import useOnClickOutside from '../../../hooks/use-onclick-outside';
import componentEnhance from '../../../lib/component-enhance';
import { runSchedulerTask } from '../../../utils/validators';
import StaticPropsContext from '../../context/static-props';
import customOptions from '../../../utils/quantity-custom-options';

const namespace = 'ui-pdp-quantity-selector';
const optionsClassname = 'ui-pdp-buybox__quantity__trigger--options';

const QuantitySelectorDesktop = ({
  message,
  picker,
  quantitySelector,
  trigger: Trigger,
  setQuantity,
  showInput,
  onShowInput,
  buttonFocus,
}) => {
  const { layout } = useContext(StaticPropsContext);
  const yieldValue = quantitySelector?.selector?.template?.yield;
  const bulkSale = !!yieldValue;
  const hasCustomOptions = customOptions.has(quantitySelector?.selector?.template?.custom_options);
  const customOptionsItems = customOptions.getItemsFormatted(quantitySelector?.selector?.template?.custom_options);
  const ref = useRef();
  const triggerRef = useRef();
  const listPickerRef = useRef();
  const inputRef = useRef();
  const [visible, setVisible] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const initialQuantity = bulkSale ? picker.selected * yieldValue : picker.selected;
  const [localQuantity, setLocalQuantity] = useState(initialQuantity);

  useEffect(() => {
    if (isClose || buttonFocus) {
      document.querySelector('#quantity-selector').focus();
    }
  }, [isClose, buttonFocus]);

  useEffect(() => {
    let observer = null;
    if (triggerRef.current && visible) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (!entry.isIntersecting) {
            setVisible(false);
          }
        },
        {
          threshold: 0.1,
        },
      );

      observer.observe(triggerRef.current);
    }
    return () => observer && observer.disconnect();
  }, [visible]);

  useEffect(() => {
    if (visible) {
      trackEvent(picker.track);
    }
  }, [visible, picker.track]);

  useEffect(() => {
    if (hasCustomOptions) {
      setLocalQuantity(initialQuantity);
    }
  }, [initialQuantity, hasCustomOptions]);

  useOnClickOutside(ref, e => {
    if (!triggerRef.current.contains(e.target)) {
      setVisible(false);
    }
  });

  const onTriggerClick = useCallback(e => {
    e.preventDefault();
    setVisible(!visible);
    setIsClose(false);
    if (hasCustomOptions) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 50);
    }
  }, [visible, hasCustomOptions]);

  const showErrorMessage = picker && picker.error_message && !picker.error_message.is_deferred;

  const onClickListItem = ({ event, quantity, selected, typeInput }) => {
    if (event) {
      event.preventDefault();
    }

    let totalQuantity = quantity;
    if (bulkSale) {
      totalQuantity = Math.ceil(parseFloat((quantity / yieldValue).toFixed(4)));
    }

    if (totalQuantity !== selected || typeInput) {
      setLocalQuantity(bulkSale ? totalQuantity * yieldValue : totalQuantity);
      const { track } = quantitySelector;
      if (hasCustomOptions) {
        // Request from the Product Team to track whether the quantity was changed by input or by clicking on the list when priced by quantity
        const selected_from = typeInput ? 'input' : 'list';
        track.melidata_event = {
          ...track.melidata_event,
          event_data: {
            ...track.melidata_event.event_data,
            selected_from,
          },
        };
      }
      startTransition(() => {
        setQuantity(totalQuantity);
        trackQuantityEvent(track, totalQuantity);
        runSchedulerTask(() => {
          HistoryService.pushParam('quantity', totalQuantity, true);
        }, 'background');
      });
    }
    setVisible(false);
  };

  const closeTooltip = e => {
    if (e.keyCode === 27) {
      setVisible(false);
      setIsClose(true);
    }
  };

  const hasOptions = !!(
    quantitySelector &&
    quantitySelector.selector.template.options &&
    quantitySelector.selector.template.options.length > 0
  );

  const hasVolumeDiscount = !!quantitySelector?.selector?.template?.volume_discount_options;

  const items = [];
  const minimumQuantity = bulkSale ? yieldValue : quantitySelector?.minimum_quantity || 1;

  const lengthRows =
    quantitySelector && !hasOptions
      ? quantitySelector.selector.rows + minimumQuantity - 1
      : quantitySelector.selector.rows;

  const finalLength = hasCustomOptions ? customOptionsItems.length : lengthRows;

  const { description, selected_label } = picker;
  const selectedLabelText =
    (!description && selected_label.values) || (bulkSale && selected_label.values)
      ? componentEnhance.jsx(selected_label.text, selected_label.values, 'ui-pdp-buybox__quantity__selected__label')
      : selected_label.text;

  if (quantitySelector) {
    let labelElement = index =>
      quantitySelector.selector?.template[(index === 1 ? 'singular' : 'plural')]?.replace('{quantity}', index);

    if (hasOptions) {
      const {
        selector: {
          template: { options },
        },
      } = quantitySelector;
      labelElement = index => componentEnhance.jsx(options[index - 1].text, options[index - 1].values);
    }

    const quantityIndex = hasOptions || hasCustomOptions ? 1 : minimumQuantity;
    const offset = minimumQuantity - 1;

    for (let index = quantityIndex; index <= finalLength; index += 1) {
      const restProps = {};
      let offsetIndex = index;
      const { amount, quantity } = (() => {
        if (hasCustomOptions) {
          return customOptionsItems[index - 1];
        }

        if (hasVolumeDiscount) {
          return quantitySelector.selector.template.volume_discount_options[index - 1];
        }

        return {};
      })();

      const showTitle = hasVolumeDiscount || hasCustomOptions;

      if (hasOptions) {
        offsetIndex = bulkSale ? index * yieldValue : offset + index;
      }

      if (hasCustomOptions) {
        offsetIndex = quantity.value;
      }

      if (index === localQuantity) {
        restProps['data-autofocus'] = true;
      }

      items.push(
        <ListItem
          key={`list-item-quantity-${index}`}
          data-testid={`quantity-selector-item-${index}`}
          selected={offsetIndex === localQuantity}
          disabled={index > quantitySelector.available_quantity}
          onClick={event => onClickListItem({ event, quantity: offsetIndex, selected: localQuantity })}
          title={showTitle ? quantity.text : labelElement(index)}
          tabIndex={0}
          rightContent={
            showTitle && (
              <MoneyAmount
                size={16}
                value={{ fraction: amount.fraction, cents: amount.cents ?? undefined }}
                suffix={amount.suffix?.text}
                currencyId={amount.currency_id}
                centsType="superscript"
              />
            )
          }
          {...restProps}
          onKeyUp={closeTooltip}
        />,
      );
    }

    const canShowLastItem =
      (quantitySelector.selector.template?.more &&
        quantitySelector.available_quantity > quantitySelector.selector.rows) ||
      (hasCustomOptions && quantitySelector.input);

    if (canShowLastItem) {
      const restProps = {};
      if (!showInput) {
        restProps.primary = hasCustomOptions ? undefined : quantitySelector.selector.template.more;
        restProps.onClick = e => {
          if (hasCustomOptions) {
            e.preventDefault();
            return;
          }
          onShowInput(true);
        };
      }
      items.push(
        <ListItem
          key="link"
          data-testid="quantity-selector-item-more"
          selected={showInput && !hasCustomOptions}
          disabled={false}
          onKeyUp={closeTooltip}
          {...restProps}
          tabIndex={-1}
        >
          {(showInput || hasCustomOptions) && (
            <QuantityInput
              listPickerRef={listPickerRef}
              key={`list-item-quantity-${picker.title}`}
              confirm={quantitySelector.input.confirm_button.label.text}
              length={hasCustomOptions ? customOptionsItems.length : quantitySelector.selector.rows}
              noStockMessage={quantitySelector.input.error_message}
              minErrorMessage={quantitySelector.input.min_error_message}
              min={minimumQuantity}
              max={bulkSale ? quantitySelector.available_quantity * yieldValue : quantitySelector.available_quantity}
              quantity={bulkSale ? formatBulkQuantity(localQuantity) : localQuantity}
              bulkSale={bulkSale}
              placeholder={hasCustomOptions ? quantitySelector.input.placeholder : undefined}
              setQuantity={quantity => onClickListItem({ quantity, selected: localQuantity, typeInput: true })}
              title={hasCustomOptions ? undefined : quantitySelector.selector.template.title ?? picker.title}
              selectorTemplate={quantitySelector?.selector?.template}
              ref={inputRef}
            />
          )}
        </ListItem>,
      );
    }
  }

  const listClasses = classnames({
    [`${namespace}__list--${layout}`]: (hasVolumeDiscount || hasCustomOptions) && !bulkSale && !hasCustomOptions,
    [`${namespace}__list--default`]: !hasVolumeDiscount && !bulkSale && !hasCustomOptions,
    [`${namespace}__list--default-bulk`]: bulkSale && !hasCustomOptions,
    [`${namespace}__list-custom-options`]: hasCustomOptions && !bulkSale && !hasOptions,
  });

  const tooltipType = hasVolumeDiscount || hasCustomOptions ? 'default' : 'dropdown';
  const offsetX = hasCustomOptions ? '0' : 3;

  return (
    <Tooltip
      id="quantity-selector"
      className={classnames(namespace, {
        [`${namespace}--options`]: (hasOptions || hasCustomOptions) && !bulkSale,
        [`${namespace}--options-bulk`]: hasOptions && bulkSale && !hasCustomOptions,
        [`${namespace}__options-custom-options`]: hasCustomOptions && !bulkSale && !hasOptions,
      })}
      content={
        <FocusScope contain restoreFocus autoFocus>
          <div ref={ref} className={listClasses}>
            <List type={tooltipType} srLabel={picker.title} ref={listPickerRef}>
              {items}
            </List>
          </div>
        </FocusScope>
      }
      offsetX={offsetX}
      offsetY={2}
      side="bottomLeft"
      trigger="click"
      open={visible}
    >
      <Trigger
        ref={triggerRef}
        title={picker.title}
        selectedLabel={selectedLabelText}
        max={quantitySelector && quantitySelector.available_quantity}
        message={bulkSale ? undefined : message}
        active={visible}
        onClick={onTriggerClick}
        bulkSale={bulkSale}
        showError={showErrorMessage}
        className={classnames({ [optionsClassname]: hasOptions })}
        hasCustomOptions={hasCustomOptions}
      />
    </Tooltip>
  );
};

QuantitySelectorDesktop.propTypes = {
  message: string,
  picker: shape({
    description: string,
    title: string.isRequired,
    selected: number,
    selected_label: shape({
      text: string.isRequired,
    }).isRequired,
    track: shape({}),
  }).isRequired,
  quantitySelector: shape({
    type: string,
    available_quantity: number,
    selector: shape({
      title: shape({
        text: string,
        color: string,
      }),
      subtitles: arrayOf(
        shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      ),
      template: shape({
        singular: string,
        plural: string,
        more: string,
        volume_discount_options: arrayOf(
          shape({
            quantity: shape({
              text: string,
            }),
            amount: shape({
              fraction: string,
              cents: string,
              suffix: shape({
                text: string,
              }),
            }),
          }),
        ),
        custom_options: arrayOf(
          shape({
            type: string,
            quantity: number,
            display_value: shape({
              amount: shape({
                type: string,
                value: number,
                currency_id: string,
                decimal_style: string,
                suffix: shape({
                  text: string,
                  accessibility_text: string,
                }),
              }),
            }),
          }),
        ),
      }).isRequired,
      rows: number,
    }).isRequired,
    input: shape({
      title: shape({
        text: string,
        color: string,
      }),
      subtitles: arrayOf(
        shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      ),
      placeholder: string,
      confirm_button: shape({ label: shape({ text: string }) }),
      error_message: shape({
        text: string,
        color: string,
      }),
    }).isRequired,
    track: shape({}),
  }),
  setQuantity: func.isRequired,
  showInput: bool.isRequired,
  trigger: oneOfType([func, shape({ current: shape() })]),
  onShowInput: func.isRequired,
  buttonFocus: bool,
};

QuantitySelectorDesktop.defaultProps = {
  message: null,
  trigger: QuantityTrigger,
  quantitySelector: null,
  buttonFocus: false,
};

export default QuantitySelectorDesktop;
