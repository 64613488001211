import React, { useContext } from 'react';
import { arrayOf, string, shape, func } from 'prop-types';
import Card, { CardContent, CardSecondary } from '@andes/card';
import { Button } from '@andes/button';
import classnames from 'classnames';
import ChevronIcon from '../../icons/chevron';
import { trackEvent } from '../../../lib/tracking';
import StaticPropsContext from '../../context/static-props';
import StyledLabel from '../../styled-label';
import componentEnhance from '../../../lib/component-enhance';

import { loadable } from 'nordic/lazy';

const CompatsWidget = loadable(() => import('./compats-mobile'));

const CompatsWidgetWrapper = ({ components, namespace, modifier, onSubmitForm, onRemove }) => {
  const { deviceType } = useContext(StaticPropsContext);
  const [onClickColapse, setOnClickColapse] = React.useState(false);
  const [lineNumber, setLineNumber] = React.useState(true);
  const [key, setKey] = React.useState(0);
  const divContentRef = React.useRef(null);
  React.useEffect(() => {
    setKey(prevKey => prevKey + 1);
    if (divContentRef.current) {
      const min_lines_for_button = components[0]?.widget_specs?.action?.min_lines_for_button ?? 6;
      const lineHeight = 15;
      const contentHeight = divContentRef.current.scrollHeight;
      const numberOfLines = Math.floor(contentHeight / lineHeight);
      setLineNumber(numberOfLines >= min_lines_for_button);
    }
  }, [components, deviceType]);

  const onClickHandler = track => {
    if (!onClickColapse && track) {
      trackEvent(track);
    }
    setOnClickColapse(!onClickColapse);
  };
  const renderActionColapse = action => (
    <Button
      hierarchy={action.hierarchy.toLowerCase()}
      size={action.size.toLowerCase()}
      onClick={() => onClickHandler(action.track)}
      fullWidth
      className={classnames(
        'compats-specs__action-button-specs',
        { 'compats-specs__action-button-specs--active': onClickColapse },
        { 'compats-specs__action-button-specs--disabled': !onClickColapse },
      )}
    >
      <span>{onClickColapse ? action.action_open.text : action.action_collapsed.text}</span>
      <ChevronIcon
        className={classnames('ui-pdp-icon--chevron-down', {
          'ui-pdp-icon--chevron-down--active': onClickColapse,
          'ui-pdp-icon--disabled': !onClickColapse,
        })}
      />
    </Button>
  );
  const renderWidgetSpecItem = (spec, as = 'li') => (
    <StyledLabel
      className={classnames('compats-specs__item-list', {
        [`ui-pdp-color--${spec.color}`]: spec.color,
        [`ui-pdp-size--${spec.font_size}`]: spec.font_size,
        [`ui-pdp-family--${spec.font_family}`]: spec.font_family,
      })}
      as={as}
      text={componentEnhance.jsx(spec.text, spec.values)}
    />
  );
  return (
    <div className={`${namespace}__compats`}>
      {components.map(component => (
        <React.Fragment key={component.type + key}>
          {component.type === 'compats_widget_block' && (
            <>
              <CompatsWidget
                {...component.widget_data}
                onSubmitForm={(selectedInput, updateState) => onSubmitForm(selectedInput, updateState, deviceType)}
                onRemove={(selectedInput, updateState) => onRemove(deviceType, updateState)}
                modifier={modifier}
              />
              {component?.widget_specs?.type === 'specs_component' && (
                <Card className="compats-specs__card" paddingSize={24}>
                  <CardContent>
                    {component.widget_specs.title && (
                      <StyledLabel
                        className={classnames('compats-specs__title', {
                          [`ui-pdp-color--${component.widget_specs.title.color}`]: component.widget_specs.title.color,
                          [`ui-pdp-size--${component.widget_specs.title.font_size}`]: component.widget_specs.title
                            .font_size,
                          [`ui-pdp-family--${component.widget_specs.title.font_family}`]: component.widget_specs.title
                            .font_family,
                        })}
                        as="h4"
                        text={component.widget_specs.title.text}
                      />
                    )}
                    <ul className="compats-specs__list">
                      {component.widget_specs.specs_list.map(spec => renderWidgetSpecItem(spec, 'li'))}
                    </ul>
                  </CardContent>
                </Card>
              )}
              {component?.widget_specs?.type === 'specs_component_message' && (
                <CardSecondary className="compats-specs__card" paddingSize={16}>
                  <CardContent
                    className={classnames(
                      { 'specs_component_message-visible': onClickColapse },
                      { 'specs_component_message-hidden': !onClickColapse && lineNumber },
                    )}
                  >
                    <div ref={divContentRef}>
                      {lineNumber && !onClickColapse && <div className="specs_component_transparent" />}
                      {component.widget_specs.title && (
                        <StyledLabel
                          className={classnames('compats-specs__title', {
                            [`ui-pdp-color--${component.widget_specs.title.color}`]: component.widget_specs.title.color,
                            [`ui-pdp-size--${component.widget_specs.title.font_size}`]: component.widget_specs.title
                              .font_size,
                            [`ui-pdp-family--${component.widget_specs.title.font_family}`]: component.widget_specs.title
                              .font_family,
                          })}
                          as="h4"
                          text={component.widget_specs.title.text}
                        />
                      )}
                      {component.widget_specs.specs_list.map(spec => renderWidgetSpecItem(spec, 'p'))}
                    </div>
                  </CardContent>
                  {lineNumber && component.widget_specs.action && renderActionColapse(component.widget_specs.action)}
                </CardSecondary>
              )}
            </>
          )}
          {component.type === 'composed_highlight' && (
            <StyledLabel
              className={`${namespace}__compats-disclaimer`}
              key={component.title.text}
              as="p"
              text={componentEnhance.jsx(component.title.text, components.values)}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

CompatsWidgetWrapper.propTypes = {
  components: arrayOf(shape({})).isRequired,
  namespace: string,
  modifier: string,
  device: string,
  onSubmitForm: func,
  onRemove: func,
};
CompatsWidgetWrapper.defaultProps = {
  namespace: null,
  modifier: null,
  device: 'desktop',
  onSubmitForm: null,
  onRemove: null,
};

export default CompatsWidgetWrapper;
