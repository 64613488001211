import React, { useContext, useRef } from 'react';
import { bool, string } from 'prop-types';
import classnames from 'classnames';
import CompatsWidget from '@ui-components-web/ml-compats/compats/desktop';
import StaticPropsContext from '../../context/static-props';
import { isMshops } from '../../../utils/mercadoShopsUtils';
import useGetPositionCompatsSticky from '../../../hooks/use-get-position-compats-sticky';

const COMPATS_MESSAGE_TOP = 'COMPATS_MESSAGE_TOP';
const COMPATS_MESSAGE = 'COMPATS_MESSAGE';
const COMPLETE = 'COMPLETE';

const CompatsWidgetStickyContainer = ({ ...props }) => {
  const containerCompatsSticky = useRef(null);
  const [isIntersecting] = useGetPositionCompatsSticky(containerCompatsSticky);
  const { site } = useContext(StaticPropsContext);
  const isShops = isMshops({ platform: { id: site } });
  const [isVisibleMessage, setIsVisibleMessage] = React.useState(false);
  const [key, setKey] = React.useState(0);

  React.useEffect(() => {
    const handleStorageChange = ev => {
      if (ev.key === COMPATS_MESSAGE_TOP) {
        setIsVisibleMessage(sessionStorage.getItem(COMPATS_MESSAGE_TOP));
        setKey(prevKey => prevKey + 1);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  return (
    props && (
      <div
        className={classnames(
          { 'ui-pdp-container__row--compats-widget-sticky': !isShops },
          { 'ui-pdp-container__row--compats-widget-sticky-mshop': isShops },
          { 'ui-pdp-compats--store': props.isOfficialStore },
          { 'ui-pdp-compats--intersecting': isIntersecting },
          { 'ui-pdp-compats--message': props.type === COMPATS_MESSAGE },
          {
            'compats-message-hidden':
              props.type === COMPATS_MESSAGE && props.class_name?.includes(COMPLETE) && !isVisibleMessage,
            'compats-message-visible':
              props.type === COMPATS_MESSAGE && props.class_name?.includes(COMPLETE) && isVisibleMessage,
          },
        )}
        id="compats-widget-top"
        ref={containerCompatsSticky}
        key={key}
      >
        <CompatsWidget {...props} />
      </div>
    )
  );
};

CompatsWidgetStickyContainer.propTypes = {
  isOfficialStore: bool,
  isWidgetSticky: bool,
  class_name: string,
  type: string,
};

CompatsWidgetStickyContainer.defaultProps = {
  isOfficialStore: false,
  isWidgetSticky: false,
  class_name: null,
  type: null,
};

export default CompatsWidgetStickyContainer;
