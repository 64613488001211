const jsonToProps = ({ available_quantity, item_id, ...rest }) => ({
  itemId: item_id || rest.form?.item_id,
  selectedQuantity:
    available_quantity && available_quantity.state !== 'HIDDEN' ? available_quantity.picker.selected : 1,
  ...rest,
  form: {
    ...rest.form,
  },
});

export default jsonToProps;
