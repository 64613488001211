import React, { useState } from 'react';
import { string, shape, node } from 'prop-types';
import { StyledLabel } from '@vpp-frontend-components/styled-label';
import BottomSheet from '@andes/bottom-sheet';
import { Button } from '@andes/button';
import CloseIcon from '../icons/close';
import componentEnhance from '../../lib/component-enhance';
import { trackEvent } from '../../lib/tracking';

const namespace = 'ui-pdp-generic-summary';

const BottomSheetWithClose = ({
  className,
  title,
  initialHeight,
  labelText,
  labelValues,
  nameKey,
  children,
  track,
}) => {
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const values = {
    ...labelValues,
    [nameKey]: {
      ...labelValues[nameKey],
      as: 'a',
      onClick: () => setOpenBottomSheet(true),
    },
  };

  const { btnProps } = values;
  const { size, hierarchy, text } = btnProps || {};

  return (
    <>
      {btnProps ? (
        <Button size={size} hierarchy={hierarchy} onClick={() => setOpenBottomSheet(true)}>
          {text}
        </Button>
      ) : (
        <div className={`${namespace}__import-fees`} data-testid="import-fees">
          <StyledLabel text={componentEnhance.jsx(labelText, values)} />
        </div>
      )}
      {openBottomSheet && (
        <BottomSheet
          className={className}
          data-testid="bottom-sheet"
          isOpen={openBottomSheet}
          onClose={() => setOpenBottomSheet(false)}
          initialHeight={initialHeight}
          title={title}
          onOpen={() => trackEvent(track)}
        >
          <Button
            data-testid="bs-close-button"
            className={`${namespace}__bs-close-button`}
            hierarchy="transparent"
            onClick={() => setOpenBottomSheet(false)}
          >
            <CloseIcon className="ui-pdp-color--WHITE" />
          </Button>
          {children}
        </BottomSheet>
      )}
    </>
  );
};

BottomSheetWithClose.propTypes = {
  className: string,
  title: string.isRequired,
  initialHeight: string.isRequired,
  labelText: string.isRequired,
  labelValues: shape({}),
  nameKey: string,
  children: node.isRequired,
  track: shape({}),
};

BottomSheetWithClose.defaultProps = {
  className: null,
  initialHeight: 'auto',
  labelValues: null,
  nameKey: 'info',
  track: null,
};

export default BottomSheetWithClose;
