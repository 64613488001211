import React from 'react';
import { number, string, shape, arrayOf, node, func } from 'prop-types';
import classNames from 'classnames';
import { Pill } from '@andes/badge';
import ReviewSummary from '../review-summary/review-summary.desktop';
import Title from '../title';
import StyledLabel from '../styled-label';
import SellerValidated from '../seller-validated';
import applyclasses from '../../lib/apply-classes';
import componentEnhance from '../../lib/component-enhance';
import Compats from '../compats-widget/highlight';
import CompatsTitle from '../compats-widget/title';

const namespace = 'ui-pdp-header';

const Header = ({
  className,
  subtitle,
  reviews,
  title,
  titleClassName,
  tag,
  bottom_subtitle,
  children,
  official_store,
  link_label,
  onScrollComponent,
  highlights,
  title_compats,
}) => (
  <div className={classNames(namespace, className)}>
    {tag && (
      <Pill
        className={applyclasses({ ...tag, className: `${namespace}__tag` })}
        color="gray"
        size="large"
        hierarchy="quiet"
        roundedCorners={['top-right', 'top-left', 'bottom-right', 'bottom-left']}
      >
        {tag.text}
      </Pill>
    )}
    {subtitle && (
      <div className={`${namespace}__subtitle`}>
        <span
          className={classNames('ui-pdp-subtitle', {
            'ui-pdp-subtitle_rex': link_label?.target === 'location_and_points',
          })}
        >
          {subtitle}
        </span>
      </div>
    )}
    <div className={`${namespace}__title-container`}>
      {title_compats ? (
        <CompatsTitle title={title} compats={title_compats} className={titleClassName} />
      ) : (
        <Title title={title} className={titleClassName} />
      )}
      {children}
    </div>
    {highlights && highlights.map(key => <Compats {...key} key={key.id} />)}
    {bottom_subtitle && <StyledLabel className={`${namespace}__bottom-subtitle`} {...bottom_subtitle} />}
    {official_store && (
      <div className={`${namespace}__store`}>{componentEnhance.jsx(official_store.text, official_store.values)}</div>
    )}
    {link_label && (
      <SellerValidated
        title={link_label.label}
        icon={link_label.icon}
        target={link_label.target}
        track={link_label.track}
        onScrollComponent={link_label.target ? onScrollComponent : null}
      />
    )}
    {reviews && (
      <div className={`${namespace}__info`}>
        <ReviewSummary {...reviews} />
      </div>
    )}
  </div>
);

Header.propTypes = {
  className: string,
  subtitle: string,
  reviews: shape({
    amount: number,
    rating: number,
    title: string,
    action: shape({
      type: string,
      target: string,
    }),
    stars: string.isRequired,
  }),
  title: string.isRequired,
  titleClassName: string,
  tag: shape({
    text: string,
    color: string,
    bgcolor: string,
  }),
  bottom_subtitle: shape({}),
  official_store: shape({
    text: string,
    values: shape({
      action: shape({
        type: string,
        target: string,
        label: shape({
          text: string,
        }),
      }),
    }),
  }),
  link_label: shape({
    label: shape({
      text: string.isRequired,
      color: string,
      font_family: string,
      font_size: string,
    }).isRequired,
    icon: shape({
      id: string,
      size: string,
    }).isRequired,
  }),
  children: node,
  ariaHidden: string,
  onScrollComponent: func,
  highlights: arrayOf(
    shape({
      description_action: shape({
        action_type: string,
        label: shape({
          bg_color: string,
          color: string,
          font_size: string,
          text: string,
          type: string,
        }),
        target: string,
        timeout: number,
        type: string,
      }),
      icon_dto: shape({
        bg_color: string,
        color: string,
        id: string,
      }),
      id: string,
      state: string,
      tag_action: shape({
        action_type: string,
        label: shape({
          bg_color: string,
          color: string,
          font_size: string,
          text: string,
          type: string,
        }),
        target: string,
        timeout: number,
        type: string,
      }),
      type: string,
    }),
  ),
  title_compats: shape({}),
};

Header.defaultProps = {
  className: '',
  subtitle: null,
  reviews: null,
  titleClassName: null,
  tag: null,
  bottom_subtitle: null,
  official_store: null,
  children: null,
  ariaHidden: 'false',
  link_label: null,
  onScrollComponent: null,
  highlights: null,
  title_compats: null,
};

export default React.memo(Header);
