import React from 'react';
import GroupedHeader from '../../../features/grouped-header/grouped-header';
import Breadcrumb from '../../../../../components/breadcrumb';
import EYI from '../../../../pdp/features/edit-your-item/edit-your-item';
import Share from '../../../../pdp/features/share/share.desktop';
import SYI from '../../../../pdp/features/sell-your-item/sell-your-item';

const groupedComponents = {};
groupedComponents.breadcrumb = Breadcrumb;
groupedComponents.edit_your_item = EYI;
groupedComponents.sell_your_item = SYI;
groupedComponents.share = Share;

const groupedHeader = () => <GroupedHeader groupedComponents={groupedComponents} />;

export default groupedHeader;
