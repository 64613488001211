import React from 'react';
import { MoneyAmount } from '@andes/money-amount';
import { string, shape, number } from 'prop-types';

const QuantityInputCustomLabel = ({
  amount: { fraction, cents, currency_id, decimal_style, suffix },
  namespace,
  label,
}) => {
  const customOptionsClassName = `${namespace}__input__custom-options`;

  return (
    <div className={`${customOptionsClassName}-quantity`}>
      <span className={`${customOptionsClassName}-quantity__label ${customOptionsClassName}-quantity__label-quantity`}>
        {label}
      </span>
      <span
        data-testid="input-label-amount"
        className={`${customOptionsClassName}-quantity__label ${customOptionsClassName}-quantity__label-amount`}
      >
        <MoneyAmount
          size={16}
          value={{ fraction, cents }}
          suffix={suffix.text}
          currencyId={currency_id}
          centsType={decimal_style || 'superscript'}
        />
      </span>
    </div>
  );
};

QuantityInputCustomLabel.propTypes = {
  label: string.isRequired,
  amount: shape({
    type: string,
    value: number,
    currency_id: string,
    decimal_style: string,
    suffix: shape({
      text: string,
      accessibility_text: string,
    }),
  }).isRequired,
  namespace: string.isRequired,
};

export default QuantityInputCustomLabel;
