import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/grouped-share-bookmark/jsonToProps';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'grouped_share_bookmark';
const SHARE = 'share';
const BOOKMARK = 'bookmark';
const WISHLIST = 'wishlist_save_button';

const mapStateToProps = (state, ownProps) => {
  const {
    components: { [SHARE]: share },
    components: { [BOOKMARK]: bookmark },
    components: { [WISHLIST]: wishlist },
    id,
  } = state;

  return jsonToProps({
    id,
    share,
    bookmark,
    wishlist,
    showOnlyForWishlistVariant: ownProps.showOnlyForWishlistVariant,
    app: 'vip',
  });
};

const mapDispatchToProps = dispatch => ({
  removeElementFromList: element => dispatch(actions.removeElementFromList(element)),
  addElementToList: element => dispatch(actions.addElementToList(element)),
  onClickBookmark: e => {
    e.preventDefault();
    return dispatch(actions.toggleBookmark());
  },
  showSnackbar: snackbar => dispatch(actions.showSnackbar(snackbar)),
  openWishlistModalBS: () => dispatch(actions.openWishlistModalBS()),
  closeWishlistModalBS: isBookmarked => dispatch(actions.closeWishlistModalBS(isBookmarked)),
  redirectToLogin: itemId =>
    dispatch(actions.redirectToLogin({ itemId, loginType: 'FAVORITE', featureName: FEATURE_NAME })),
});

const enhance = compose(
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const groupedShareBookmark = GroupedShareBookmark => enhance(GroupedShareBookmark);

export default groupedShareBookmark;
