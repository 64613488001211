import { useState, useEffect } from 'react';

const useGetPositionCompatsSticky = ref => {
  const [initialPosition, setInitialPosition] = useState();
  const [verticalPosition, setVerticalPosition] = useState();
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [ticking, setTicking] = useState(false);

  /* istanbul ignore next */
  const getPosition = () => {
    const y = ref?.current?.offsetTop;
    setVerticalPosition(y);

    if (!ticking) {
      window.requestAnimationFrame(() => {
        if (verticalPosition > initialPosition) {
          setIsIntersecting(true);
        } else {
          setIsIntersecting(false);
        }
        setTicking(false);
      });
      setTicking(true);
    }
  };

  useEffect(() => {
    setInitialPosition(ref?.current?.offsetTop);
  }, [ref]);

  useEffect(() => {
    window.addEventListener('scroll', getPosition);
    return () => {
      window.removeEventListener('scroll', getPosition);
    };
  });

  return [isIntersecting];
};

export default useGetPositionCompatsSticky;
