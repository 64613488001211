/**
 * Module dependencies
 */
import React from 'react';

import { StoreConsumer } from './context';
import ErrorBoundary from './error';
import getDisplayName from '../lib/get-display-name';

const noop = () => {};

/**
 * connect
 */
const connect = (mapStateToProps = noop, mapDispatchToProps = noop) => WrappedComponent => {
  const ConnectedComponent = ownProps => (
    <StoreConsumer>
      {({ history, state, dispatch }) => (
        <ErrorBoundary>
          <>
            <WrappedComponent
              history={history}
              {...mapStateToProps(state, ownProps)}
              {...mapDispatchToProps(dispatch, ownProps)}
            />
          </>
        </ErrorBoundary>
      )}
    </StoreConsumer>
  );
  ConnectedComponent.displayName = `Connect(${getDisplayName(WrappedComponent)})`;
  return ConnectedComponent;
};

/**
 * Expose connect
 */
export default connect;
