/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useMemo, useEffect } from 'react';

import usePrevious from '../../../hooks/use-previous';
import { PROVIDER } from '../../figure/constants';

const isItemClip = item => item?.provider === PROVIDER.CLIP;

const useGallery = (props = {}) => {
  const { initPosition = 0, items, clipsConfig } = props;

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(initPosition);
  const prevIndex = usePrevious(currentIndex);

  const [currentIndexClip, setCurrentIndexClip] = useState(() =>
    clipsConfig?.hasClips ? items.findIndex(item => isItemClip(item)) : -1,
  );
  const prevIndexClip = usePrevious(currentIndexClip);

  const nextIndexClip = useMemo(() => {
    let nextClipIndex = -1;

    if (clipsConfig?.size > 1 && currentIndexClip !== -1) {
      nextClipIndex = items.findIndex((item, index) => index > currentIndexClip && isItemClip(item));

      if (nextClipIndex === -1) {
        // istanbul ignore next
        nextClipIndex = items.findIndex(item => isItemClip(item));
      }
    }

    return nextClipIndex;
  }, [items, currentIndexClip]);

  const currentItem = useMemo(() => items[currentIndex], [items, currentIndex]);
  const previousItem = useMemo(() => items[prevIndex] || {}, [items, prevIndex]);

  const currentItemClip = useMemo(() => items[currentIndexClip] || {}, [items, currentIndexClip]);
  const nextItemClip = useMemo(() => items[nextIndexClip] || {}, [items, nextIndexClip]);
  const previousItemClip = useMemo(() => items[prevIndexClip] || {}, [items, prevIndexClip]);

  const isCurrentClips = isItemClip(currentItem) && currentIndex === currentIndexClip;
  const isPreviousClips = isItemClip(previousItem) && currentIndex !== prevIndex;

  useEffect(() => {
    if (clipsConfig?.size > 1) {
      const currentClipIndex = items.findIndex((item, index) => {
        const isCurrentOrNext = typeof prevIndexClip === 'number' ? index === currentIndex : index >= currentIndex;

        return isCurrentOrNext && isItemClip(item);
      });

      if (currentClipIndex !== -1) {
        setCurrentIndexClip(currentClipIndex);
      }
    }
  }, [items, currentIndex, prevIndexClip]);

  return {
    position: {
      current: currentIndex,
      currentClip: currentIndexClip,
      prevClip: prevIndexClip,
      nextClip: nextIndexClip,
      previous: prevIndex,
    },
    snap: {
      current: currentItem,
      currentClip: currentItemClip,
      nextClip: nextItemClip,
      prevClip: previousItemClip,
      previous: previousItem,
    },
    isCurrentClips,
    isFullscreen,
    isPreviousClips,
    setCurrentIndex,
    setIsFullscreen,
  };
};

export default useGallery;
export { isItemClip };
