import React from 'react';
import { shape, func, string } from 'prop-types';
import classnames from 'classnames';
import object from '../../lib/global';
import ActionModal from '../action-modal';
import Iframe from '../iframe';
import transformActionTarget from '../../utils/transformActionTarget';

const getAction = (namespace, action, onIframeMessage, onCouponUpdate, actionModalRef, actionModalProps) => {
  const lowEndUrl = { ...action };
  transformActionTarget(lowEndUrl, { modal: false });

  let couponModalChanged = false;

  function messageHandler(event) {
    if (event?.data?.event_from === 'coupon-modal' && event?.data?.type === 'coupons_actions') {
      couponModalChanged = true;
      object.removeEventListener('message', messageHandler);
    }
  }

  const onCloseModal = () => {
    if (couponModalChanged && onCouponUpdate) {
      couponModalChanged = false;
      object.removeEventListener('message', messageHandler);
      onCouponUpdate();
    }
  };

  const onOpenModal = () => {
    if (object?.addEventListener) {
      object.addEventListener('message', messageHandler);
    }
  };

  return (
    <ActionModal
      label={action.label}
      modalTitle={action.modal_title}
      modalClassName={classnames(
        `${namespace}-modal ${action.modal_title ? null : 'modal-close-button-mobile'}`,
        'ui-pdp-iframe-modal',
        actionModalProps?.className,
      )}
      className={`${namespace}__action`}
      track={action.track}
      closeTrack={action.close_track}
      url={lowEndUrl.target}
      closeModalLabel={action.close_modal_label}
      controlModalHeader
      showCloseButton
      onCloseModal={onCloseModal}
      onOpenModal={onOpenModal}
      customRef={actionModalRef}
      {...actionModalProps}
    >
      <Iframe
        title={action.label ? action.label.text : null}
        src={action.target}
        onMessage={onIframeMessage}
        scrolling="no"
        width="100%"
      />
    </ActionModal>
  );
};

getAction.propTypes = {
  namespace: string,
  onIframeMessage: func,
  onCouponUpdate: func.isRequired,
  action: shape({}),
};

getAction.defaultProps = {
  namespace: '',
  onIframeMessage: null,
  onCouponUpdate: null,
  action: null,
};

export default getAction;
