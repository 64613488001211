import React, { useState, useEffect, forwardRef } from 'react';
import { func, number, shape, string, arrayOf, bool } from 'prop-types';
import { TextField } from '@andes/textfield';
import { Button } from '@andes/button';
import StyledLabel from '../../styled-label';
import { generateKeyToComponent } from '../../../utils/validators';
import classnames from 'classnames';
import QuantityInputCustomLabel from './quantity-input-custom-label';
import customOptions from '../../../utils/quantity-custom-options';

const namespace = 'ui-pdp-buybox__quantity';

const QuantityInput = forwardRef(
  (
    {
      confirm,
      length,
      min,
      listPickerRef,
      max,
      minErrorMessage,
      noStockMessage,
      setQuantity,
      bulkSale,
      quantity,
      subtitles,
      placeholder,
      title,
      mobile,
      selectorTemplate,
    },
    ref,
  ) => {
    const { custom_options } = selectorTemplate;
    const hasCustomOptions = customOptions.has(custom_options);
    const keyboardInputMode = bulkSale || hasCustomOptions ? 'decimal' : 'text';
    const [error, setError] = useState(null);
    const [value, setValue] = useState(() => {
      if (hasCustomOptions) {
        return customOptions.verifyQuantity(custom_options, quantity);
      }
      if (bulkSale || quantity > length) {
        return String(quantity);
      }
      return '';
    });
    const quantityText = customOptions.getQuantityText(value, selectorTemplate);
    const amount = customOptions.getAmountByQuantity(custom_options, value);
    const onSubmit = e => {
      if (e) {
        e.persist();
      }

      const numericValue = Number(value.replace(',', '.'));
      if (max && numericValue > max) {
        setError({
          modifier: 'error',
          message: bulkSale ? noStockMessage.text.replace('&nbsp;', '\u00A0') : noStockMessage.text,
        });
      } else if (min && numericValue < min) {
        setError({
          modifier: 'error',
          message: bulkSale ? minErrorMessage.text.replace('&nbsp;', '\u00A0') : minErrorMessage.text,
        });
      } else {
        setQuantity(numericValue, { trackSourceElement: 'input' });
      }
    };

    useEffect(() => {
      if (error?.modifier === 'error' && bulkSale && !mobile && listPickerRef?.current) {
        listPickerRef.current.scrollTop = listPickerRef.current.scrollHeight;
      }
    }, [error, listPickerRef, mobile, bulkSale]);

    const onInputChange = e => {
      setError(null);

      if (bulkSale) {
        // eslint-disable-next-line security/detect-unsafe-regex
        const regex = /^\d+(?:[,.]\d{0,2})?$/;
        if (regex.test(e.target.value) || e.target.value === '') {
          setValue(e.target.value);
        }
      } else {
        setValue(e.target.value.replace(/[^0-9]/g, '').replace(/\b0+/g, ''));
      }
    };

    const ButtonComponent = props => (
      <Button
        data-testid="quantity-input-button"
        className={classnames({
          [`${namespace}__button`]: !bulkSale,
          [`${namespace}__button-bulk`]: bulkSale,
          [`${namespace}__button-custom-options`]: hasCustomOptions,
        })}
        onClick={onSubmit}
        disabled={error !== null || value === ''}
        {...props}
      >
        {confirm}
      </Button>
    );

    const customOptionsClassName = `${namespace}__input__custom-options`;

    useEffect(() => {
      if (hasCustomOptions) {
        setValue(customOptions.verifyQuantity(custom_options, quantity));
        setError(null);
      }
    }, [quantity, hasCustomOptions, custom_options]);

    return (
      <div
        data-testid={hasCustomOptions ? 'input-container' : undefined}
        className={classnames(`${namespace}__input`, { [customOptionsClassName]: hasCustomOptions })}
      >
        {subtitles &&
          subtitles.map(subtitle => (
            <StyledLabel
              key={generateKeyToComponent(subtitle)}
              className={classnames(`${namespace}__stock`, {
                [`${namespace}__stock__yield`]: bulkSale,
                [`${namespace}__stock__custom-options`]: hasCustomOptions,
              })}
              as="p"
              {...subtitle}
            />
          ))}
        <div className={classnames(`${namespace}__input-form`)}>
          <TextField
            inputProps={{ inputMode: keyboardInputMode, autoComplete: 'off' }}
            className={`${namespace}__input-textfield`}
            data-testid="quantity-input"
            autoFocus
            setField={inputRef => {
              if (ref) {
                ref.current = inputRef;
              }
            }}
            value={value}
            placeholder={placeholder}
            onChange={onInputChange}
            onKeyDown={e => {
              if (e.key === 'Enter' && value !== '') {
                onSubmit();
              }
            }}
            label={title}
            {...error}
          >
            {!mobile && <ButtonComponent size="small" />}
          </TextField>
          {!error && hasCustomOptions && value > 0 && (
            <QuantityInputCustomLabel label={quantityText} amount={amount} namespace={namespace} />
          )}
          {mobile && <ButtonComponent />}
        </div>
      </div>
    );
  },
);

QuantityInput.propTypes = {
  confirm: string.isRequired,
  length: number.isRequired,
  listPickerRef: shape({ current: shape({}) }),
  min: number,
  max: number,
  bulkSale: bool,
  minErrorMessage: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
  noStockMessage: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
  quantity: number.isRequired,
  setQuantity: func.isRequired,
  subtitles: arrayOf(shape({ text: string.isRequired })),
  placeholder: string,
  title: string,
  mobile: bool,
  selectorTemplate: shape({
    custom_options: arrayOf(
      shape({
        type: string,
        quantity: number,
        display_value: shape({
          amount: shape({
            type: string,
            value: number,
            currency_id: string,
            decimal_style: string,
            suffix: shape({
              text: string,
              accessibility_text: string,
            }),
          }),
        }),
      }),
    ),
  }),
};

QuantityInput.defaultProps = {
  max: null,
  title: null,
  listPickerRef: null,
  bulkSale: false,
  placeholder: null,
  subtitles: null,
  mobile: false,
  selectorTemplate: {},
};

export default QuantityInput;
