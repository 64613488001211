import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/buybox/actions/jsonToProps';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'main_actions';
const AVIALABLE_QUANTITY = 'available_quantity';

const mapStateToProps = ({
  components: { [FEATURE_NAME]: mainActions },
  components: { [AVIALABLE_QUANTITY]: available_quantity },
  shops_validator: { shop: { id: shopId } = {} } = {},
}) => jsonToProps({ ...mainActions, available_quantity, shopId });

const mapDispatchToProps = dispatch => ({
  showQuantityError: () => dispatch(actions.showQuantityError()),
  showVariationsError: (deviceType, actionKey) => dispatch(actions.showVariationsError(deviceType, actionKey)),
  showAddToCartModal: ({ itemId, labelText }) => dispatch(actions.showAddToCartModal({ itemId, labelText })),
  showQuestionsAiModal: ({ target, disabled, called_from, source }) =>
    dispatch(actions.showQuestionsAiModal({ target, disabled, called_from, source })),
  triggerBuyNowOnePayForAll: actionKey => dispatch(actions.triggerBuyNowOnePayForAll(actionKey)),
  triggerSequencer: buttonKey => dispatch(actions.triggerSequencer(buttonKey)),
  saveFrontendStatsd: data => dispatch(actions.saveFrontendStatsd(data)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const mainActions = MainActions => enhance(MainActions);

export default mainActions;
export { mapStateToProps, mapDispatchToProps };
