import React, { useRef, useEffect, useState } from 'react';
import { bool, node, number, oneOf, shape, string, func } from 'prop-types';
import classnames from 'classnames';
import ActionCollapsableExpand from './action-collapsable-expand';
import ActionCollapsableModal from './action-collapsable-modal';
import { trackEvent } from '../../lib/tracking';

/* constants */
const namespace = 'ui-pdp-collapsable';
const MARGIN_BOX = 32;
const GAP = 10;

// eslint-disable-next-line complexity
const ActionCollapsable = ({
  action,
  collapse,
  allowRecollapse,
  children,
  className,
  max,
  maxCollapsed,
  gradientType,
  collapseBy,
  scrollToTop,
  onClickAction,
  showAction,
  withModal,
  modalHash,
  modalClassName,
  modalTitle,
  useCanSeeEverything,
}) => {
  const ref = useRef();
  const [collapsedState, setCollapsedState] = useState({
    currentHeight: false,
    collapsedHeight: false,
    collapsed: false,
    viewportHeight: false,
    maxHeight: false,
    collapsable: false,
  });
  const actionExpandCollapse = !collapsedState.collapsed && allowRecollapse ? collapse : action;

  const onClick = e => {
    e.preventDefault();
    setCollapsedState({
      ...collapsedState,
      collapsed: !collapsedState.collapsed,
      maxHeight: false,
    });

    if (scrollToTop) {
      ref.current.scrollIntoView();
    }

    trackEvent(actionExpandCollapse?.track);

    if (onClickAction) {
      onClickAction();
    }
  };

  useEffect(() => {
    if (!collapsedState.currentHeight) {
      // TODO: revisar impacto en performance de este if
      const currentHeight = ref.current.offsetHeight;
      const viewportHeight = collapseBy === 'pages' ? window.innerHeight : 1;
      const maxHeight = Math.floor(viewportHeight * max);
      if (
        (collapseBy === 'pages' && currentHeight > maxHeight * maxCollapsed) ||
        (collapseBy === 'pixels' && currentHeight > maxCollapsed)
      ) {
        setCollapsedState({
          currentHeight,
          collapsed: true,
          collapsedHeight: viewportHeight * maxCollapsed,
          maxHeight,
          collapsable: true,
        });
      }
    }
  }, [ref, collapsedState.currentHeight, collapsedState.maxHeight, max, maxCollapsed, collapseBy]);

  const { collapsed, collapsable, collapsedHeight, maxHeight, currentHeight } = collapsedState;
  const containerMaxHeight = collapsed ? collapsedHeight : maxHeight;
  const extendedHeight = currentHeight - MARGIN_BOX - GAP;
  const canSeeEverything = maxCollapsed >= extendedHeight; // content preview height === all content height
  const showButton =
    ((collapsable && allowRecollapse) || collapsed) &&
    action &&
    action.label &&
    (useCanSeeEverything ? !canSeeEverything : true);

  const isCollapsedClassNames = {
    [`${namespace}--is-collapsed`]: useCanSeeEverything ? collapsed && !canSeeEverything : collapsed,
    [`${namespace}--is-gradient-small`]: gradientType === 'small',
  };

  const seeMoreNoModal = showAction && showButton && !withModal;
  const seeMoreModal = showAction && showButton && withModal;

  const expandCollapseComponent = (
    <ActionCollapsableExpand action={actionExpandCollapse} collapsed={collapsed} onClick={onClick} />
  );

  return (
    <div className={classnames(namespace, className, isCollapsedClassNames)} ref={ref}>
      {seeMoreNoModal && !collapsed && expandCollapseComponent}
      <div
        className={`${namespace}__container`}
        style={containerMaxHeight ? { maxHeight: `${containerMaxHeight}px` } : {}}
      >
        {children}
      </div>
      {seeMoreNoModal && collapsed && expandCollapseComponent}
      {seeMoreModal && (
        <ActionCollapsableModal
          action={action}
          modalClassName={modalClassName}
          modalHash={modalHash}
          modalTitle={modalTitle}
        >
          {children}
        </ActionCollapsableModal>
      )}
    </div>
  );
};

ActionCollapsable.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }).isRequired,
  collapse: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }),
  allowRecollapse: bool,
  children: node,
  className: string,
  gradientType: oneOf(['default', 'small']),
  collapseBy: oneOf(['pages', 'pixels']),
  max: number,
  maxCollapsed: number,
  modalClassName: string,
  modalHash: string,
  modalTitle: string,
  scrollToTop: bool,
  onClickAction: func,
  showAction: bool,
  withModal: bool,
  useCanSeeEverything: bool,
};
ActionCollapsable.defaultProps = {
  allowRecollapse: false,
  collapse: null,
  children: null,
  className: null,
  gradientType: 'default',
  collapseBy: 'pages',
  maxCollapsed: 1,
  max: 1.5,
  modalClassName: null,
  modalHash: null,
  modalTitle: null,
  scrollToTop: true,
  onClickAction: null,
  showAction: true,
  withModal: false,
  useCanSeeEverything: false,
};

export default ActionCollapsable;
