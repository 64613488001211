import {
  FETCH_START,
  FETCH_ERROR,
  TOGGLE_BOOKMARK_OPTIMISTICALLY,
  FETCH_ON_SET_BOOKMARK_COMPLETE,
  QUANTITY_SHOW_INPUT_CHANGE,
  FETCH_ON_SET_QUANTITY_COMPLETE,
  FETCH_ON_SHIPPING_UPDATE_COMPLETE,
  FETCH_ON_SET_QUANTITY_START,
  FETCH_ON_SHIPPING_UPDATE_START,
  FETCH_COMPLETE,
  SHOW_QUANTITY_ERROR,
  SHOW_VARIATIONS_ERROR_DESKTOP,
  SHOW_VARIATIONS_ERROR_MOBILE,
  CREATE_QUESTION_START,
  CREATE_QUESTION_COMPLETE,
  CREATE_QUESTION_FROM_AI_COMPLETE,
  CREATE_QUESTION_ERROR,
  FETCH_COMPATIBLE_POSIBLE_VALUES_START,
  FETCH_COMPATIBLE_POSIBLE_VALUES_COMPLETE,
  FETCH_COMPATIBLE_POSIBLE_VALUES_ERROR,
  SET_COMPATIBILITIES_INPUT_VALUE,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_START,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_COMPLETE,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_ERROR,
  FETCH_COMPATS_SEARCH_START,
  FETCH_COMPATS_SEARCH_COMPLETE,
  FETCH_COMPATS_SEARCH_COMPLETE_MOBILE,
  FETCH_COMPATS_SEARCH_STICKY_START,
  FETCH_COMPATS_SEARCH_STICKY_COMPLETE,
  FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE,
  FETCH_COMPATS_SEARCH_ERROR,
  FETCH_COMPATS_DELETE_START,
  FETCH_COMPATS_DELETE_COMPLETE,
  FETCH_COMPATS_DELETE_COMPLETE_MOBILE,
  FETCH_COMPATS_DELETE_ERROR,
  FETCH_COMPATS_FEEDBACK_CLOSE,
  ADD_TO_CART_UPDATE_START,
  ADD_TO_CART_UPDATE_COMPLETE,
  ADD_TO_CART_UPDATE_ERROR,
  MAKE_QUESTION,
  MAKE_QUESTION_ERROR,
  MAKE_QUESTION_SUCCESS,
  SET_SMART_ANSWER,
  CLOSE_SMART_ANSWER_MODAL,
  OPEN_QUESTION_FROM_SA_MODAL,
  TOGGLE_BOOKMARKED,
  FETCH_ON_COUPON_UPDATE_START,
  FETCH_ON_COUPON_UPDATE_COMPLETE,
  FETCH_ON_COUPON_UPDATE_ERROR,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  ON_DEMAND_IFRAME,
  CART_CONGRATS_UPDATE,
  FETCH_SEQUENCER,
  FETCH_ON_MELIPLUS_UPDATE_START,
  FETCH_ON_MELIPLUS_UPDATE_COMPLETE,
  PRELOAD_IFRAME,
  WISHLIST_SHOW_BOTTOMSHEET_MODAL,
  WISHLIST_CLOSE_BOTTOMSHEET_MODAL,
  FETCH_ON_DEMAND_IFRAME,
  UPDATE_GIFT_REGISTRY,
  TOGGLE_FOLLOW_OPTIMISTICALLY,
  FETCH_ON_SET_FOLLOW_COMPLETE,
  FETCH_QUESTION_AI_START,
  FETCH_QUESTION_AI_COMPLETE,
  FETCH_QUESTION_AI_ERROR,
  UPDATE_GIFT_REGISTRY_CHECKBOX,
  INITIAL_QUESION_AI,
  FETCH_ON_CHANGE_INSTALLATION_COMPLETE,
  FETCH_ON_CHANGE_INSTALLATION_START,
  FETCH_ON_CHANGE_INSTALLATION_END,
} from '../../actions/vip/types';

import {
  FETCH_CONTACT_INFO_ERROR,
  FETCH_COMPONENT,
  CREATE_QUESTIONS_UNREGISTERED_START,
  CREATE_QUESTIONS_UNREGISTERED_COMPLETE,
  CREATE_QUESTIONS_UNREGISTERED_ERROR,
  FETCH_SELLER_INFO_PHONES,
  FETCH_MODAL_QUESTIONS_START,
  FETCH_MODAL_QUESTIONS_COMPLETE,
  FETCH_MODAL_QUESTIONS_ERROR,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_START,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_COMPLETE,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_ERROR,
  SHOW_RECAPTCHA,
  REMOVE_RECAPTCHA,
  QUESTIONS_UNREGISTERED_ERROR_FORM,
  QUESTIONS_UNREGISTERED_ON_CHANGE_VALUE,
  RENDER_FRAUD_MODAL,
  QUESTIONS_UNREGISTERED_EDIT_DATASTORED,
  MODAL_REQUEST_START,
  MODAL_REQUEST_CLOSE,
  MODAL_REQUEST_RESIZING,
  SHOW_FRAUD_SNACKBAR,
  SCROLL_TO_COMPONENT,
  UPDATE_TOKEN_RESPONSE,
  FETCH_BUDGET_COMPONENTS,
  CREATE_BUDGET_START,
  CREATE_BUDGET_ERROR,
  FETCH_QUOTATION_START,
  FETCH_QUOTATION_COMPLETE,
  FETCH_QUOTATION_PRICE,
  FETCH_QUOTATION_ERROR,
  UPDATE_QUOTATION_FORM,
  UPDATE_QUOTATION_CURRENT_STATE,
  CREATE_QUOTATION_START,
  CREATE_QUOTATION_SHOW_PRICE,
  CREATE_QUOTATION_COMPLETE,
  CREATE_QUOTATION_ERROR,
  FETCH_GET_UNITS_START,
  FETCH_GET_UNITS_COMPLETE,
  UPDATE_GET_UNITS_CURRENT_STATE,
  FETCH_GET_UNITS_ERROR,
  FETCH_VEHICLE_HISTORY_MODAL,
  QUOTATION_ERROR_EXCEEDS_LIMIT,
} from '../../actions/vip/vis/types';

import { MAKE_QUESTION_SA, SMART_ANSWER_MODAL, RESULTS } from '../../components/qadb/qadb.constants';
import updateArrayComponents from '../helpers/update-array-components';
import { getUpdatedCompatsState, getUpdatedCompatsMobileState } from '../helpers/compats-utils';
import { messagesErrorScreen } from '../../lib/get-message-error';
import findActionSelected from '../helpers/main-actions';

// eslint-disable-next-line complexity
const vipCoreReducer = (state, action) => {
  const questionsComponent = action.component ? action.component : 'questions';
  const variationsComponent = state.components.variations?.state === 'VISIBLE' ? 'variations' : 'outside_variations';
  switch (action.type) {
    case FETCH_START:
      return { ...state, isFetching: true, error: null };

    case FETCH_ON_SET_QUANTITY_START:
      return {
        ...state,
        components: {
          ...state.components,
          available_quantity: {
            ...state.components.available_quantity,
            isFetching: true,
            buttonFocus: false,
          },
        },
        isFetching: true,
      };

    case FETCH_ON_SET_QUANTITY_COMPLETE:
      return {
        ...state,
        ...action.payload,
        components: {
          ...state.components,
          ...action.payload.components,
          ...(state.components.compats_feedback
            ? {
                compats_feedback: {
                  ...state.components.compats_feedback,
                },
              }
            : {}),
          ...(state.components.technical_specifications
            ? {
                technical_specifications: {
                  ...state.components.technical_specifications,
                },
              }
            : {}),
          ...(state.components.highlighted_specs_attrs
            ? {
                highlighted_specs_attrs: {
                  ...state.components.highlighted_specs_attrs,
                },
              }
            : {}),
          ...(state.components.highlighted_specs_attrs_swap
            ? {
                highlighted_specs_attrs_swap: {
                  ...state.components.highlighted_specs_attrs_swap,
                },
              }
            : {}),
          ...(state.components.highlighted_specs_attrs_third_swap
            ? {
                highlighted_specs_attrs_third_swap: {
                  ...state.components.highlighted_specs_attrs_third_swap,
                },
              }
            : {}),
          available_quantity: {
            ...action.payload.components.available_quantity,
            isFetching: false,
            buttonFocus: true,
          },
        },
        preload: action.payload.preload,
        isFetching: false,
      };

    case FETCH_ON_SHIPPING_UPDATE_START:
      return { ...state, isFetching: true, error: null, selectedShippingOptionId: action.shippingOptionId };

    case FETCH_ON_SHIPPING_UPDATE_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    case FETCH_ON_MELIPLUS_UPDATE_START:
      return { ...state, isFetching: true, error: null };

    case FETCH_ON_MELIPLUS_UPDATE_COMPLETE:
      return {
        ...state,
        components: {
          ...state.components,
          ...action.payload.components,
          fixed: {
            ...state.components.fixed,
            ...action.payload.components.fixed,
          },
          ...updateArrayComponents(state.components, action.payload.components),
        },
        preload: action.payload.preload,
        isFetching: false,
      };

    case FETCH_ERROR:
      return { ...state, isFetching: false, error: action.error };

    case TOGGLE_BOOKMARK_OPTIMISTICALLY:
      return {
        ...state,
        components: {
          ...state.components,
          bookmark: {
            ...state.components.bookmark,
            is_bookmarked: !state.components.bookmark.is_bookmarked,
            isFetching: true,
          },
        },
      };

    case FETCH_ON_SET_BOOKMARK_COMPLETE:
      return {
        ...state,
        components: {
          ...state.components,
          bookmark: {
            ...state.components.bookmark,
            is_bookmarked:
              action.payload && action.payload.status === 'ok'
                ? state.components.bookmark.is_bookmarked
                : !state.components.bookmark.is_bookmarked,
            isFetching: false,
          },
          onboarding: action.payload && action.payload.push_notification_modal,
        },
      };

    case FETCH_ON_COUPON_UPDATE_START:
      return {
        ...state,
        components: {
          ...state.components,
          coupon_summary: {
            ...state.components.coupon_summary,
            isFetching: true,
          },
        },
      };

    case FETCH_ON_COUPON_UPDATE_COMPLETE:
      return {
        ...state,
        components: {
          ...state.components,
          coupon_summary: {
            ...action.payload.components.coupon_summary,
            isFetching: false,
          },
        },
      };

    case FETCH_ON_COUPON_UPDATE_ERROR:
      return {
        ...state,
        components: {
          ...state.components,
          coupon_summary: {
            ...state.components.coupon_summary,
            isFetching: false,
          },
        },
      };

    case TOGGLE_BOOKMARKED:
      return {
        ...state,
        components: {
          ...state.components,
          bookmark: {
            ...state.components.bookmark,
            is_bookmarked: !state.components.bookmark.is_bookmarked,
            isFetching: false,
          },
        },
      };

    case QUANTITY_SHOW_INPUT_CHANGE:
      return {
        ...state,
        components: {
          ...state.components,
          available_quantity: {
            ...state.components.available_quantity,
            showQuantityInput: action.payload.show,
          },
        },
      };

    case FETCH_COMPLETE:
      return {
        ...state,
        id: action.payload.id,
        pathName: action.payload.pathName,
        parentUrl: action.payload.parentUrl,
        attributes: action.payload.attributes,
        variation_id: action.payload.variation_id || null,
        questions: action.payload.questions,
        components: {
          ...state.components,
          ...action.payload.components,
          ...(state.components.compats_feedback
            ? {
                compats_feedback: {
                  ...state.components.compats_feedback,
                },
              }
            : {}),
          ...(state.components.technical_specifications
            ? {
                technical_specifications: {
                  ...state.components.technical_specifications,
                },
              }
            : {}),
          ...(state.components.highlighted_specs_attrs
            ? {
                highlighted_specs_attrs: {
                  ...state.components.highlighted_specs_attrs,
                },
              }
            : {}),
          ...(state.components.highlighted_specs_attrs_swap
            ? {
                highlighted_specs_attrs_swap: {
                  ...state.components.highlighted_specs_attrs_swap,
                },
              }
            : {}),
          ...(state.components.highlighted_specs_attrs_third_swap
            ? {
                highlighted_specs_attrs_third_swap: {
                  ...state.components.highlighted_specs_attrs_third_swap,
                },
              }
            : {}),
        },
        track: action.payload.track,
        preload: action.payload.preload,
        isFetching: false,
        error: null,
      };

    case FETCH_BUDGET_COMPONENTS:
      return {
        ...state,
        components: {
          ...state.components,
          new_budget_message: { ...action.payload.components.new_budget_message },
          budget_sent_message: { ...action.payload.components.budget_sent_message },
          service_flash_message: {
            id: 'service_flash_message',
            type: 'service_flash_message',
            ...action.payload.service_flash_message,
          },
          budget_demand: { ...action.payload.components.budget_demand },
        },
        grouped_main_actions: { ...action.payload.components.main_actions },
        service_flash_message: {
          id: 'service_flash_message',
          type: 'service_flash_message',
          ...action.payload.service_flash_message,
        },
      };
    case SHOW_QUANTITY_ERROR: {
      let errorMessage;
      if (state.components.available_quantity.picker.error_message) {
        errorMessage = {
          ...state.components.available_quantity.picker.error_message,
          is_deferred: state.components.available_quantity.picker.error_message.is_deferred
            ? null
            : state.components.available_quantity.picker.error_message.is_deferred,
        };
      }
      return {
        ...state,
        components: {
          ...state.components,
          available_quantity: {
            ...state.components.available_quantity,
            picker: {
              ...state.components.available_quantity.picker,
              border_color: errorMessage ? 'red' : null,
              error_message: errorMessage,
            },
          },
        },
      };
    }
    case SHOW_VARIATIONS_ERROR_DESKTOP: {
      const hasPickers = state.components.variations.pickers?.length > 0;
      if (!hasPickers) {
        return state;
      }
      return {
        ...state,
        components: {
          ...state.components,
          variations: {
            ...state.components.variations,
            pickers: state.components.variations.pickers.map(picker => {
              if (!picker.error_message) {
                return picker;
              }
              return {
                ...picker,
                error_message: {
                  ...picker.error_message,
                  is_deferred: picker.error_message.is_deferred ? null : picker.error_message.is_deferred,
                },
              };
            }),
          },
        },
      };
    }

    case SHOW_VARIATIONS_ERROR_MOBILE: {
      const hasPickers = state.components[variationsComponent].pickers?.length > 0;
      if (!hasPickers) {
        return state;
      }
      return {
        ...state,
        components: {
          ...state.components,
          [variationsComponent]: {
            ...state.components[variationsComponent],
            pickers: state.components[variationsComponent].pickers.map(picker => {
              if (!picker.error_message) {
                return picker;
              }
              return {
                ...picker,
                error_message: {
                  ...picker.error_message,
                  is_deferred: picker.error_message.is_deferred ? null : picker.error_message.is_deferred,
                },
              };
            }),
            requestKey: Math.random(),
            actionSelected: findActionSelected(state, action.payload.actionKey),
          },
        },
      };
    }

    case CREATE_QUESTION_START:
      return {
        ...state,
        [questionsComponent]: {
          ...state[questionsComponent],
          questions_ai: {
            ...state[questionsComponent].questions_ai,
            isFetching: false,
          },
          messages: null,
          disabled: true,
          isFetching: true,
        },
      };

    case CREATE_QUESTION_COMPLETE:
      return {
        ...state,
        components: {
          ...state.components,
          onboarding: action.payload.push_notification_modal,
        },
        [questionsComponent]: {
          ...state[questionsComponent],
          disabled: false,
          messages: {
            ...action.payload.display_message,
            type: action.payload.success === true ? 'success' : 'error',
          },
          isFetching: false,
          stockModal: action.payload.stock_modal,
          stockTrack: action.payload.stock_track,
          empty_state: action.payload.model ? action.payload.model.empty_state : null,
          ...action.payload.model,
        },
      };

    case CREATE_QUESTION_FROM_AI_COMPLETE:
      return {
        ...state,
        components: {
          ...state.components,
          onboarding: action.payload.push_notification_modal,
        },
        [questionsComponent]: {
          ...state[questionsComponent],
          questions_ai: {
            ...state[questionsComponent].questions_ai,
            questions_ai_form: {
              ...state[questionsComponent].questions_ai.questions_ai_form,
              form: {
                ...state[questionsComponent].questions_ai?.questions_ai_form?.form,
                is_ask_seller: true,
              },
            },
            questions_ai_suggestion: {
              state: 'HIDDEN',
            },
            isFetching: false,
          },
          disabled: false,
          isFetching: false,
          stockModal: action.payload.stock_modal,
          stockTrack: action.payload.stock_track,
          empty_state: action.payload.model ? action.payload.model.empty_state : null,
          ...action.payload.model,
        },
      };

    case CREATE_QUESTION_ERROR: {
      const { title, description } = messagesErrorScreen(state.siteId);
      const errorText = `${title} - ${description}`;
      return {
        ...state,
        [questionsComponent]: {
          ...state[questionsComponent],
          questions_ai: {
            ...state[questionsComponent].questions_ai,
            isFetching: false,
          },
          messages: {
            text: errorText,
            type: 'error',
          },
          disabled: false,
          isFetching: false,
        },
      };
    }

    case CREATE_QUESTIONS_UNREGISTERED_START: {
      const { component, form } = action;
      return {
        ...state,
        [component]: {
          ...state[component],
          form,
          messages: null,
          disabled: true,
          isFetching: true,
        },
      };
    }

    case CREATE_QUESTIONS_UNREGISTERED_COMPLETE: {
      if (state.unregistered_questions) {
        delete state.unregistered_questions.recaptchaPublicKey;
      }
      const { component } = action;
      let newState = {};
      if (component === 'unregistered_questions_modal') {
        newState = {
          ...state,
          [component]: {
            ...state[component],
            disabled: false,
            form: {
              ...action.payload.unregistered_questions.form,
              question: {
                ...action.payload.unregistered_questions.form.question,
                value: action.payload.success === true ? '' : action.payload.unregistered_questions.form.question.value,
              },
            },
            isFetching: false,
            empty_state: action.payload.model ? action.payload.model.empty_state : null,
            ...action.payload.model,
          },
          unregistered_questions: {
            ...state.unregistered_questions,
            messages: {
              ...action.payload.display_message,
              type: action.payload.success === true ? 'success' : 'error',
            },
            key: Math.random(),
          },
        };
      } else {
        newState = {
          ...state,
          [component]: {
            ...state[component],
            disabled: false,
            form: {
              ...action.payload.unregistered_questions.form,
              question: {
                ...action.payload.unregistered_questions.form.question,
                value: action.payload.success === true ? '' : action.payload.unregistered_questions.form.question.value,
              },
            },
            messages: {
              ...action.payload.display_message,
              type: action.payload.success === true ? 'success' : 'error',
            },
            isFetching: false,
            empty_state: action.payload.model ? action.payload.model.empty_state : null,
            key: Math.random(),
            ...action.payload.model,
          },
        };
      }
      return newState;
    }

    case CREATE_QUESTIONS_UNREGISTERED_ERROR: {
      const { title, description } = messagesErrorScreen(state.siteId);
      const errorText = `${title} - ${description}`;
      const { component } = action;
      let newState = {};
      if (component === 'unregistered_questions_modal') {
        newState = {
          ...state,
          [component]: {
            ...state[component],
            errorBackupForm: action.form,
            disabled: false,
            isFetching: false,
          },
          unregistered_questions: {
            ...state.unregistered_questions,
            messages: {
              text: errorText,
              type: 'error',
            },
            key: Math.random(),
          },
        };
      } else {
        newState = {
          ...state,
          [component]: {
            ...state[component],
            errorBackupForm: action.form,
            messages: {
              text: errorText,
              type: 'error',
            },
            key: Math.random(),
            disabled: false,
            isFetching: false,
          },
        };
      }
      return newState;
    }

    case MAKE_QUESTION: {
      const makeQuestion = state.components.qadb.qadb_data.components.find(
        component => component.id === MAKE_QUESTION_SA,
      );
      makeQuestion.initialValue = action.payload.question;

      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            makequestion: {
              ...state.components.qadb.makequestion,
              disabled: true,
            },
          },
        },
      };
    }

    case MAKE_QUESTION_SUCCESS: {
      const components = Array.from(state.components.qadb.qadb_data.components);

      const makeQuestion = state.components.qadb.qadb_data.components.find(
        component => component.id === MAKE_QUESTION_SA,
      );
      makeQuestion.initialValue = '';

      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            qadb_data: {
              ...state.components.qadb.qadb_data,
              components: [...components.filter(component => component.id !== RESULTS), action.payload.results],
              query: '',
            },
            smartAnswerModal: { isOpen: false, isAsking: false },
            makequestion: {
              ...state.components.qadb.makequestion,
              disabled: false,
            },
            snackMessage: {
              key: Math.random(),
              show: true,
              type: 'success',
              message: action.payload.message,
            },
          },
        },
      };
    }

    case SET_SMART_ANSWER: {
      let smart = state.components.qadb.qadb_data.components.find(component => component.id === SMART_ANSWER_MODAL);
      const makeQuestion = state.components.qadb.qadb_data.components.find(
        component => component.id === MAKE_QUESTION_SA,
      );

      makeQuestion.initialValue = '';

      if (smart) {
        smart = action.payload;
      } else {
        state.components.qadb.qadb_data.components.push(action.payload);
      }

      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            smartAnswer: action.payload,
            smartAnswerModal: { isOpen: true, isAsking: false },
            makequestion: {
              ...state.components.qadb.makequestion,
              disabled: false,
            },
          },
        },
      };
    }

    case MAKE_QUESTION_ERROR: {
      const makeQuestion = state.components.qadb.qadb_data.components.find(
        component => component.id === MAKE_QUESTION_SA,
      );
      makeQuestion.initialValue = '';
      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            snackMessage: { key: Math.random(), show: true, type: 'error', message: action.payload.message },
            makequestion: {
              ...state.components.qadb.makequestion,
              disabled: false,
            },
          },
        },
      };
    }

    case CLOSE_SMART_ANSWER_MODAL:
      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            smartAnswerModal: {
              isOpen: false,
              isAsking: false,
            },
          },
        },
      };

    case OPEN_QUESTION_FROM_SA_MODAL:
      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            smartAnswerModal: {
              isOpen: false,
              isAsking: true,
            },
          },
        },
      };

    case FETCH_CONTACT_INFO_ERROR: {
      const { component } = action;
      const { title, description } = messagesErrorScreen(state.siteId);
      const errorText = `${title} - ${description}`;
      return {
        ...state,
        components: {
          ...state.components,
          [component]: {
            ...state.components[component],
            message: {
              show: true,
              text: errorText,
              type: 'error',
              key: Math.random(),
            },
          },
        },
      };
    }

    case FETCH_SELLER_INFO_PHONES: {
      return {
        ...state,
        seller_profile: {
          ...state.components.seller_profile,
          phones: action.payload.phones,
        },
        seller_profile_rex: {
          ...state.components.seller_profile_rex,
          phones: action.payload.phones,
        },
      };
    }

    case FETCH_COMPONENT: {
      const { component, isFetchingData } = action;
      Object.keys(state).forEach(key => {
        if (state[key]) {
          if (state[key].messages) {
            delete state[key].messages;
          }
          if (state[key].recaptchaPublicKey) {
            delete state[key].recaptchaPublicKey;
          }
        }
      });

      return {
        ...state,
        [component]: {
          ...state[component],
          isFetchingData,
        },
        doContactAction: null,
      };
    }
    case FETCH_MODAL_QUESTIONS_START:
      return {
        ...state,
        questions_modal: {},
        message_modal: {},
      };

    case FETCH_MODAL_QUESTIONS_COMPLETE:
      return {
        ...state,
        questions_modal: action.payload.questions_modal,
        message_modal: {},
      };

    case FETCH_MODAL_QUESTIONS_ERROR: {
      const { title, description } = messagesErrorScreen(state.siteId);
      const errorText = `${title} - ${description}`;
      return {
        ...state,
        questions_modal: {},
        message_modal: {
          text: errorText,
          type: 'error',
        },
      };
    }

    case FETCH_MODAL_UNREGISTERED_QUESTIONS_START:
      if (state.unregistered_questions_modal) {
        delete state.unregistered_questions_modal.recaptchaPublicKey;
      }
      return {
        ...state,
        unregistered_questions: {
          ...state.unregistered_questions,
          recaptchaPublicKey: null,
        },
        message_modal: {},
      };

    case FETCH_MODAL_UNREGISTERED_QUESTIONS_COMPLETE:
      return {
        ...state,
        unregistered_actions: action.payload.unregistered_actions,
        unregistered_questions_modal: { ...action.payload.unregistered_questions_modal },
        message_modal: {},
      };

    case REMOVE_RECAPTCHA: {
      const { component } = action.payload;

      return {
        ...state,
        [component]: {
          ...state[component],
          recaptchaPublicKey: null,
        },
      };
    }

    case UPDATE_TOKEN_RESPONSE: {
      const { component, token } = action.payload;

      return {
        ...state,
        [component]: {
          ...state[component],
          responseToken: token,
        },
      };
    }

    case FETCH_MODAL_UNREGISTERED_QUESTIONS_ERROR: {
      const { title, description } = messagesErrorScreen(state.siteId);
      const errorText = `${title} - ${description}`;
      return {
        ...state,
        unregistered_questions_modal: {},
        message_modal: {
          text: errorText,
          type: 'error',
        },
      };
    }

    case QUESTIONS_UNREGISTERED_EDIT_DATASTORED: {
      const { canEdit, isEditing, component } = action;
      return {
        ...state,
        [component]: {
          ...state[component],
          can_edit: canEdit,
          isEditing,
        },
      };
    }

    case QUESTIONS_UNREGISTERED_ON_CHANGE_VALUE: {
      const { input, value } = action.payload;
      Object.keys(state).forEach(key => {
        if (state[key] && state[key].form && state[key].form[input]) {
          state[key].form[input].value = value;
        }
      });

      return {
        ...state,
      };
    }

    case QUESTIONS_UNREGISTERED_ERROR_FORM: {
      const { form, component } = action.payload;

      return {
        ...state,
        [component]: {
          ...state[component],
          form,
        },
      };
    }

    case RENDER_FRAUD_MODAL: {
      return {
        ...state,
        components: {
          ...state.components,
          onboarding: action.modal,
        },
      };
    }

    case SHOW_RECAPTCHA: {
      const { recaptcha, component, recaptchaActionType } = action.payload;
      return {
        ...state,
        [component]: {
          ...state[component],
          recaptchaPublicKey: recaptcha.public_key,
          key: Math.random(),
          recaptchaActionType,
        },
      };
    }

    case MODAL_REQUEST_START:
      return {
        ...state,
        modal_request: {
          isOpen: true,
          resizing: Math.random(),
        },
      };

    case MODAL_REQUEST_CLOSE:
      return {
        ...state,
        modal_request: {
          isOpen: false,
        },
      };

    case MODAL_REQUEST_RESIZING:
      return {
        ...state,
        modal_request: {
          ...state.modal_request,
          resizing: Math.random(),
        },
      };

    case SHOW_FRAUD_SNACKBAR: {
      const { component, display_message, success } = action.payload;

      Object.keys(state).forEach(key => {
        if (state[key] && state[key].messages) {
          delete state[key].messages;
        }
      });

      return {
        ...state,
        [component]: {
          ...state[component],
          messages: {
            text: display_message,
            type: success === true ? 'success' : 'error',
            key: Math.random(),
          },
        },
      };
    }

    case SCROLL_TO_COMPONENT: {
      const { component, focus } = action.payload;
      return { ...state, [component]: { ...state[component], focus } };
    }

    case FETCH_COMPATIBLE_POSIBLE_VALUES_START:
      return {
        ...state,
        components: {
          ...state.components,
          compatibilities: {
            ...state.components.compatibilities,
            isFetchingFilter: true,
            successFilter: null,
          },
        },
      };

    case FETCH_COMPATIBLE_POSIBLE_VALUES_ERROR:
      return {
        ...state,
        components: {
          ...state.components,
          compatibilities: {
            ...state.components.compatibilities,
            isFetchingFilter: false,
            successFilter: false,
          },
        },
      };

    case FETCH_COMPATIBLE_POSIBLE_VALUES_COMPLETE:
      return {
        ...state,
        components: {
          ...state.components,
          compatibilities: {
            ...state.components.compatibilities,
            isFetchingFilter: false,
            successFilter: true,
            inputs: action.inputs,
            message: action.message,
          },
        },
      };

    case SET_COMPATIBILITIES_INPUT_VALUE:
      return {
        ...state,
        components: {
          ...state.components,
          compatibilities: {
            ...state.components.compatibilities,
            isFetchingFilter: false,
            successFilter: true,
            inputs: Array.from(state.components.compatibilities.inputs, input => {
              if (input.label.id === action.labelId) {
                input.selected_value = action.value;
              }
              return input;
            }),
          },
        },
      };

    case FETCH_COMPATIBILITIES_WITH_USER_SELECTION_START:
      return {
        ...state,
        components: {
          ...state.components,
          compatibilities: {
            ...state.components.compatibilities,
            isFetching: true,
            success: null,
          },
        },
      };

    case FETCH_COMPATIBILITIES_WITH_USER_SELECTION_ERROR:
      return {
        ...state,
        components: {
          ...state.components,
          compatibilities: {
            ...state.components.compatibilities,
            isFetching: false,
            success: false,
          },
        },
      };

    case FETCH_COMPATIBILITIES_WITH_USER_SELECTION_COMPLETE:
      return {
        ...state,
        components: {
          ...state.components,
          compatibilities: {
            ...state.components.compatibilities,
            isFetching: false,
            success: true,
            action: action.payload.action,
            message: null,
            modal: {
              ...state.components.compatibilities.modal,
              user_selection: action.payload.modal.user_selection,
              message: action.payload.modal.message,
              table: action.payload.modal.table,
            },
          },
        },
      };

    case FETCH_COMPATS_SEARCH_START:
    case FETCH_COMPATS_SEARCH_STICKY_START:
    case FETCH_COMPATS_DELETE_START:
      return {
        ...state,
      };

    case FETCH_COMPATS_SEARCH_STICKY_COMPLETE:
      return getUpdatedCompatsState(state, action);

    case FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE:
      return getUpdatedCompatsMobileState(state, action);

    case FETCH_COMPATS_SEARCH_COMPLETE:
      return getUpdatedCompatsState(state, action);

    case FETCH_COMPATS_SEARCH_COMPLETE_MOBILE:
      return getUpdatedCompatsMobileState(state, action);

    case FETCH_COMPATS_DELETE_COMPLETE:
      return getUpdatedCompatsState(state, action);

    case FETCH_COMPATS_DELETE_COMPLETE_MOBILE: {
      return getUpdatedCompatsMobileState(state, action);
    }

    case FETCH_COMPATS_SEARCH_ERROR:
    case FETCH_COMPATS_DELETE_ERROR:
      return {
        ...state,
        components: {
          ...state.components,
          compats_widget: {
            ...state.components.compats_widget,
            widget_data: {
              ...state.components.compats_widget.widget_data,
              error: true,
            },
          },
        },
      };

    case FETCH_COMPATS_FEEDBACK_CLOSE:
      return {
        ...state,
        components: {
          ...state.components,
          compats_feedback: {
            ...action.payload.updateState,
          },
        },
      };

    case CREATE_BUDGET_START:
      return {
        ...state,
        components: {
          ...state.components,
          budget_demand: {
            ...state.components.budget_demand,
            messages: null,
            disabled: true,
            isFetching: true,
          },
        },
      };

    case CREATE_BUDGET_ERROR:
      return {
        ...state,
        components: {
          ...state.components,
          budget_demand: {
            ...state.components.budget_demand,
            messages: {
              ...action.payload.display_message,
              type: 'error',
            },
            disabled: false,
            isFetching: false,
          },
        },
      };

    case FETCH_QUOTATION_START:
      return {
        ...state,
        quotation_modal: {},
      };

    case FETCH_QUOTATION_COMPLETE:
      if (state.quotation && state.quotation.form) {
        return {
          ...state,
          quotation: {
            ...state.quotation,
            ...action.payload,
            form: state.quotation.form,
          },
        };
      }

      return {
        ...state,
        quotation: {
          ...state.quotation,
          ...action.payload,
        },
      };

    case FETCH_QUOTATION_PRICE: {
      return {
        ...state,
        quotation: {
          ...action.payload,
        },
      };
    }

    case FETCH_QUOTATION_ERROR: {
      const { title, description } = messagesErrorScreen(state.siteId);
      const errorText = `${title} - ${description}`;
      return {
        ...state,
        quotation: {},
        quotation_modal: {
          message: {
            text: errorText,
            type: 'error',
          },
        },
      };
    }

    case UPDATE_QUOTATION_FORM: {
      return {
        ...state,
        quotation: {
          ...state.quotation,
          form: {
            ...state.quotation.form,
            form: {
              ...state.quotation.form.form,
              ...action.payload,
            },
          },
        },
      };
    }

    case UPDATE_QUOTATION_CURRENT_STATE: {
      return {
        ...state,
        quotation: {
          ...state.quotation,
          currentState: action.payload,
        },
      };
    }

    case CREATE_QUOTATION_START:
      return {
        ...state,
        quotation: {
          ...state.quotation,
          isPostingQuotation: true,
        },
        quotation_modal: {},
      };

    case CREATE_QUOTATION_SHOW_PRICE:
      return {
        ...state,
        quotation: {
          ...state.quotation,
          isShowPrice: true,
        },
        quotation_modal: {
          message: {
            text:
              action.payload === 'congrats'
                ? state.quotation.snackbar.congrats.message
                : state.quotation.snackbar.show_price.message,
            type: 'success',
          },
        },
      };

    case CREATE_QUOTATION_COMPLETE:
      return {
        ...state,
        quotation: {
          ...state.quotation,
          isPostingQuotation: false,
        },
      };

    case CREATE_QUOTATION_ERROR: {
      const errorText = `Algo salió mal, por favor inténtalo de nuevo.`;
      return {
        ...state,
        quotation: {
          ...state.quotation,
          isPostingQuotation: false,
        },
        quotation_modal: {
          message: {
            text: errorText,
            type: 'error',
          },
        },
      };
    }

    case QUOTATION_ERROR_EXCEEDS_LIMIT:
      return {
        ...state,
        quotation: {
          ...state.quotation,
          isPostingQuotation: false,
          exceedQuotationLimit: true,
        },
        quotation_modal: {
          message: {
            text: action.payload.message,
            type: 'error',
          },
        },
      };

    case FETCH_GET_UNITS_START:
      return {
        ...state,
        multifamily_modal: {},
      };

    case FETCH_GET_UNITS_COMPLETE:
      return {
        ...state,
        multifamily: {
          ...state.multifamily,
          ...action.payload,
        },
      };

    case UPDATE_GET_UNITS_CURRENT_STATE: {
      return {
        ...state,
        multifamily: {
          ...state.multifamily,
          currentState: action.payload,
        },
      };
    }

    case FETCH_GET_UNITS_ERROR: {
      const { title, description } = messagesErrorScreen(state.siteId);
      const errorText = `${title} - ${description}`;
      return {
        ...state,
        multifamily: {},
        multifamily_modal: {
          message: {
            text: errorText,
            type: 'error',
          },
        },
      };
    }

    case ADD_TO_CART_UPDATE_START:
      return {
        ...state,
        components: {
          ...state.components,
          cart_actions: {
            ...state.components.cart_actions,
            card_tooltip: state.components.cart_actions.card_tooltip
              ? {
                  ...state.components.cart_actions.card_tooltip,
                  isFetching: true,
                }
              : null,
          },
        },
      };

    case ADD_TO_CART_UPDATE_COMPLETE:
      return {
        ...state,
        components: {
          ...state.components,
          cart_actions: {
            ...action.payload.components.cart_actions,
            card_tooltip: action.payload.components.cart_actions.card_tooltip
              ? {
                  ...action.payload.components.cart_actions.card_tooltip,
                  isFetching: false,
                }
              : null,
          },
          shipping_summary: {
            ...action.payload.components.shipping_summary,
          },
          second_shipping_summary: action.payload.components.second_shipping_summary
            ? {
                ...action.payload.components.second_shipping_summary,
              }
            : { state: 'HIDDEN' },
          pick_up_summary: {
            ...action.payload.components.pick_up_summary,
          },
          payment_summary: {
            styles: state.components.payment_summary.styles,
            ...action.payload.components.payment_summary,
          },
        },
        feedback: {
          ...action.payload.feedback,
          key: Math.random(),
        },
      };

    case ADD_TO_CART_UPDATE_ERROR:
      return {
        ...state,
        components: {
          ...state.components,
          cart_actions: {
            ...state.components.cart_actions,
            card_tooltip: state.components.cart_actions.card_tooltip
              ? {
                  ...state.components.cart_actions.card_tooltip,
                  isFetching: false,
                }
              : null,
          },
        },
        error: action.error,
      };

    case SHOW_SNACKBAR:
      return {
        ...state,
        components: {
          ...state.components,
          snackbar: {
            state: 'VISIBLE',
            message: action.params.message,
            type: action.params.type,
            delay: action.params.delay,
            called_from: action.params.called_from,
            show: true,
            action: action.params.action,
            className: action.params.className,
          },
        },
      };

    case HIDE_SNACKBAR:
      return {
        ...state,
        components: {
          ...state.components,
          snackbar: {
            called_from: state.components?.snackbar?.called_from,
            state: 'HIDDEN',
          },
        },
      };

    case ON_DEMAND_IFRAME:
      return {
        ...state,
        components: {
          ...state.components,
          on_demand_iframe: {
            ...state.components.on_demand_iframe,
            ...action.params,
          },
        },
        isFetching: typeof action.isFetching !== 'undefined' ? action.isFetching : state.isFetching, // TODO: porque en el root tiene esto y en el core no?
      };
    case CART_CONGRATS_UPDATE:
      return {
        ...state,
        parentUrl: action.payload.parentUrl,
        selected_offer_type: action.payload.selected_offer_type,
        components: {
          ...state.components,
          ...action.payload.components,
          payment_summary: {
            styles: state.components.payment_summary.styles,
            ...action.payload.components.payment_summary,
          },
        },
        buttonFocus: true,
      };
    case FETCH_ON_DEMAND_IFRAME:
      return {
        ...state,
        components: {
          ...state.components,
          main_actions: {
            ...state.components.main_actions,
            actions: state.components.main_actions.actions.map(act =>
              act.label.text === action.params.labelText ? { ...act, loading: action.params.loading } : act,
            ),
          },
        },
        isFetching: action.params.isFetching,
      };
    case FETCH_SEQUENCER:
      return {
        ...state,
        components: {
          ...state.components,
          main_actions: {
            ...state.components.main_actions,
            actions: action.payload.actions,
          },
        },
        isFetching: action.payload.isFetching,
      };

    case PRELOAD_IFRAME:
      return {
        ...state,
        preload: {
          ...state.preload,
          ...action.params,
        },
      };

    case WISHLIST_SHOW_BOTTOMSHEET_MODAL:
      return {
        ...state,
        components: {
          ...state.components,
          wishlist_save_button: {
            ...state.components.wishlist_save_button,
            targetDynamic: action.payload?.targetDynamic || state.components?.wishlist_save_button?.target,
            show: true,
          },
        },
      };

    case WISHLIST_CLOSE_BOTTOMSHEET_MODAL:
      return {
        ...state,
        components: {
          ...state.components,
          bookmark: {
            ...state.components.bookmark,
            is_bookmarked: action.payload?.isBookmarked || state.components?.bookmark?.is_bookmarked,
          },
          wishlist_save_button: {
            ...state.components.wishlist_save_button,
            targetDynamic: action.payload?.targetDynamic || state.components?.wishlist_save_button?.target,
            show: false,
          },
        },
      };

    case UPDATE_GIFT_REGISTRY: {
      return {
        ...state,
        components: {
          ...state.components,
          bookmark: {
            ...state.components.bookmark,
            is_bookmarked: action.payload?.isBookmarked || state.components?.bookmark?.is_bookmarked,
          },
          wishlist_save_button: {
            ...state.components.wishlist_save_button,
            label: action.payload?.wishlist_save_button?.action?.label?.text,
            gift_registry: {
              ...action.payload?.wishlist_save_button?.gift_registry,
              checkbox: {
                ...action.payload?.wishlist_save_button?.gift_registry?.checkbox,
                check: action.payload?.check || action.payload?.wishlist_save_button?.gift_registry?.checkbox?.check,
              },
            },
          },
        },
      };
    }

    case UPDATE_GIFT_REGISTRY_CHECKBOX: {
      return {
        ...state,
        components: {
          ...state.components,
          wishlist_save_button: {
            ...state.components.wishlist_save_button,
            gift_registry: {
              ...state?.components.wishlist_save_button?.gift_registry,
              checkbox: {
                ...state?.components.wishlist_save_button?.gift_registry?.checkbox,
                check: action.payload?.check,
              },
            },
          },
        },
      };
    }

    case FETCH_VEHICLE_HISTORY_MODAL: {
      const { payload } = action;
      return {
        ...state,
        vehicle_history_specs: {
          ...state.vehicle_history_specs,
          modalComponents: payload,
        },
      };
    }

    case TOGGLE_FOLLOW_OPTIMISTICALLY: {
      const sellerDataComponents = Array.from(state.components.seller_data.components);
      const indexItem = sellerDataComponents.findIndex(el => el.id === 'seller_header');
      const newCtaStatus =
        sellerDataComponents[indexItem].followers.cta_status === 'stop_follow' ? 'do_follow' : 'stop_follow';
      sellerDataComponents[indexItem] = {
        ...sellerDataComponents[indexItem],
        followers: {
          ...sellerDataComponents[indexItem].followers,
          cta_status: newCtaStatus,
          isFetching: true,
        },
      };

      return {
        ...state,
        components: {
          ...state.components,
          seller_data: {
            ...state.components.seller_data,
            components: sellerDataComponents,
          },
        },
      };
    }

    case FETCH_ON_SET_FOLLOW_COMPLETE: {
      const sellerDataComponents = Array.from(state.components.seller_data.components);
      const indexItem = sellerDataComponents.findIndex(el => el.id === 'seller_header');
      let newCtaStatus = sellerDataComponents[indexItem].followers.cta_status;

      if (action.payload && action.payload.status !== 'SUCCESS') {
        newCtaStatus = newCtaStatus === 'stop_follow' ? 'do_follow' : 'stop_follow';
      }

      sellerDataComponents[indexItem] = {
        ...sellerDataComponents[indexItem],
        followers: {
          ...sellerDataComponents[indexItem].followers,
          cta_status: newCtaStatus,
          isFetching: false,
        },
      };

      return {
        ...state,
        components: {
          ...state.components,
          seller_data: {
            ...state.components.seller_data,
            components: sellerDataComponents,
          },
        },
      };
    }

    case FETCH_QUESTION_AI_START:
      return {
        ...state,
        questions: {
          ...state[questionsComponent],
          questions_ai: {
            ...state[questionsComponent].questions_ai,
            isFetching: true,
          },
        },
      };

    case FETCH_QUESTION_AI_COMPLETE:
      return {
        ...state,
        questions: {
          ...state[questionsComponent],
          questions_ai: {
            ...state[questionsComponent].questions_ai,
            ...action.payload,
            isFetching: false,
          },
        },
      };

    case FETCH_QUESTION_AI_ERROR: {
      return {
        ...state,
        questions: {
          ...state[questionsComponent],
          questions_ai: {
            ...state[questionsComponent].questions_ai,
            isFetching: false,
          },
        },
        error: action.error,
      };
    }

    case INITIAL_QUESION_AI:
      return {
        ...state,
        [questionsComponent]: {
          ...state[questionsComponent],
          questions_ai: {
            ...state[questionsComponent].questions_ai,
            questions_ai_form: {
              ...state[questionsComponent].questions_ai.questions_ai_form,
              form: {
                ...state[questionsComponent].questions_ai?.questions_ai_form?.form,
                is_ask_seller: false,
              },
            },
          },
        },
      };

    case FETCH_ON_CHANGE_INSTALLATION_START:
      return {
        ...state,
        components: {
          ...state.components,
          installations: {
            ...state.components.installations,
          },
          main_actions: {
            ...state.components.main_actions,
          },
          shipping_summary: {
            ...state.components.shipping_summary,
          },
          pick_up_summary: {
            ...state.components.pick_up_summary,
          },
          returns_summary: {
            ...state.components.returns_summary,
          },
          second_shipping_summary: {
            ...state.components.second_shipping_summary,
          },
          meliplus_summary: {
            ...(state.components.meliplus_summary || { state: 'hidden' }),
          },
          upselling_meliplus_summary: {
            ...(state.components.upselling_meliplus_summary || { state: 'hidden' }),
          },
        },
        isFetching: true,
      };
    case FETCH_ON_CHANGE_INSTALLATION_END:
      return {
        ...state,
        components: {
          ...state.components,
          installations: {
            ...state.components.installations,
          },
          main_actions: {
            ...state.components.main_actions,
          },
          shipping_summary: {
            ...state.components.shipping_summary,
          },
          pick_up_summary: {
            ...state.components.pick_up_summary,
          },
          returns_summary: {
            ...state.components.returns_summary,
          },
          second_shipping_summary: {
            ...state.components.second_shipping_summary,
          },
          meliplus_summary: {
            ...(state.components.meliplus_summary || { state: 'hidden' }),
          },
          upselling_meliplus_summary: {
            ...(state.components.upselling_meliplus_summary || { state: 'hidden' }),
          },
        },
        isFetching: false,
      };
    case FETCH_ON_CHANGE_INSTALLATION_COMPLETE: {
      const components = action.payload.components.short_description ?? action.payload.components;
      const installations = components.find(component => component.id === 'installations') || {};
      const main_actions = components.find(component => component.id === 'main_actions') || {};
      const shippingSummary = components.find(component => component.id === 'shipping_summary') || { state: 'HIDDEN' };
      const pickUpSummary = components.find(component => component.id === 'pick_up_summary') || { state: 'HIDDEN' };
      const returnsSummary = components.find(component => component.id === 'returns_summary') || { state: 'HIDDEN' };
      const secondShippingSummary = components.find(component => component.id === 'second_shipping_summary') || {
        state: 'HIDDEN',
      };
      const meliplusSummary = components.find(component => component.id === 'meliplus_summary') || {
        state: 'HIDDEN',
      };
      const upsellingMeliplusSummary = components.find(component => component.id === 'upselling_meliplus_summary') || {
        state: 'HIDDEN',
      };
      return {
        ...state,
        components: {
          ...state.components,
          installations: {
            ...installations,
          },
          main_actions: {
            ...main_actions,
          },
          shipping_summary: {
            ...shippingSummary,
          },
          pick_up_summary: {
            ...pickUpSummary,
          },
          returns_summary: {
            ...returnsSummary,
          },
          second_shipping_summary: {
            ...secondShippingSummary,
          },
          meliplus_summary: {
            ...meliplusSummary,
          },
          upselling_meliplus_summary: {
            ...upsellingMeliplusSummary,
          },
        },
        isFetching: false,
      };
    }

    default:
      return state;
  }
};

export default vipCoreReducer;
