import React from 'react';
import { number, string } from 'prop-types';
import Textfield from '@andes/textfield';
import classnames from 'classnames';

const namespace = 'ui-pdp-buybox__quantity';

const QuantityNative = ({ label, message, quantity, className }) => (
  <div className={classnames(`${namespace}__lowend`, className)}>
    <Textfield
      className={`${namespace}__lowend__input`}
      message={message}
      messageFixed
      name="quantity"
      label={label}
      labelFixed
      defaultValue={quantity}
      autoComplete="off"
      type="number"
      pattern="[0-9]*"
    />
  </div>
);

QuantityNative.propTypes = {
  className: string,
  label: string.isRequired,
  message: string,
  quantity: number.isRequired,
};

QuantityNative.defaultProps = {
  className: null,
  message: null,
};

export default React.memo(QuantityNative);
