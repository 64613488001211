import React from 'react';
import { bool, func, string, shape } from 'prop-types';
import Modal from '@andes/modal';

const namespace = 'ui-pdp-iframe-reviews';

/**
 * @TODO llevar a actionmodal
 */

const IFrameReviews = ({ action, title, hashId, visible, onClose }) => (
  <Modal
    url={`#${hashId}`}
    closeUrl=""
    closeButtonSrLabel={action.close_modal_label}
    className={namespace}
    open={visible}
    onClose={e => onClose(e)}
    srLabel={title}
  >
    <iframe
      id={namespace}
      data-testid={namespace}
      title={title}
      className={`${namespace}__content`}
      src={action.target}
      width="100%"
      height="100%"
      scrolling="auto"
      frameBorder="0"
    />
  </Modal>
);

IFrameReviews.propTypes = {
  action: shape({
    target: string.isRequired,
  }).isRequired,
  hashId: string.isRequired,
  title: string,
  visible: bool,
  onClose: func,
};

IFrameReviews.defaultProps = {
  visible: false,
  onClose: null,
  title: null,
};

export default IFrameReviews;
